import React from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import HttpClient from "../../utils/httpClient";
import moment from "moment";
import { Container, Row, Col, Nav, Navbar } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import BlogHeader from "../blogHead";
import N from "../../images/social/n.svg";
import N2 from "../../images/social/n2.svg";
import N3 from "../../images/social/n3.svg";
import N4 from "../../images/social/n4.svg";
import DumyImg from "../../images/dumy.png";
import Footer from "../../components/Footer/index";
import { Flag } from "react-bootstrap-icons";
// import { blogData } from "../blogData";

const Index = () => {
  let filterBlog = [];
  let { id } = useParams();
  const navigate = useNavigate();
  const targetDivRef = React.useRef(null);

  const [allBlogData, setAllBlogData] = React.useState([]);
  const [tableContent, setTableContent] = React.useState([]);
  const [recentPost, setRecentPost] = React.useState([]);
  const [filterBlogData, setFilterBlogData] = React.useState({});
  const [loading, setLoading] = React.useState(false);

  // filterBlog = blogData.filter((item) => item.id == id);

  React.useEffect(() => {
    // setFilterBlogData(filterBlog[0]);
    getBlogData(id);
    if (targetDivRef.current) {
      targetDivRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [id]);

  const getBlogData = async () => {
    setLoading(true);
    let result = await HttpClient.requestDataBlog(
      "get?auth=b3JkZXJfYmxvZ19zZXJ2aWNlX2FwaV9ieXRl",
      "GET"
    );
    // console.log("blog data in detail", result);
    if (result) {
      setLoading(false);
      setAllBlogData(result.data);
      filterBlog = result.data.filter((item) => item.id == id);
      console.log("filterBlog", filterBlog);
      let data = filterBlog[0];
      setFilterBlogData(data);
      getTableOfContent(data.id);
    }
  };

  const getTableOfContent = async (id) => {
    let data = {
      blog_id: id,
    };
    let result = await HttpClient.requestDataBlog(
      "details?auth=b3JkZXJfYmxvZ19zZXJ2aWNlX2FwaV9ieXRl",
      "POST",
      data
    );
    console.log("getTableOfContent---", result);
    if (result) {
      const newData = result.data.map((item, index) => ({
        ...item,
        id: index + 1,
      }));

      // console.log("newData", newData);
      setTableContent(newData);
      setRecentPost(result.recentPost);
    }
  };

  const handleSelectNews = (filterId, title) => {
    let modifiedTitle = title.replace(/[^a-zA-Z0-9_]/g, "-");
    filterBlog = allBlogData.filter((item) => item.id == filterId);
    setFilterBlogData(filterBlog[0]);
    navigate(`/blog-detail/${filterId}/${modifiedTitle}`);
  };

  const handleFacebookShare = () => {
    const shareUrl = window.location.href;
    const quote = filterBlogData.title;

    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${shareUrl}&quote=${quote}`,
      "facebook-share-dialog",
      "width=600,height=400"
    );
  };

  const handleTwitterShare = () => {
    const shareUrl = window.location.href;
    const title = filterBlogData.title;

    window.open(
      `https://twitter.com/intent/tweet?url=${shareUrl}&text=${title}`,
      "twitter-share-dialog",
      "width=600,height=400"
    );
  };

  const handleLinkedinShare = () => {
    const shareUrl = window.location.href;
    const title = filterBlogData.title;

    window.open(
      `https://www.linkedin.com/sharing/share-offsite/?url=${shareUrl}&title=${title}`,
      "linkedin-share-dialog",
      "width=600,height=400"
    );
  };

  const handleEmailShare = () => {
    const shareUrl = window.location.href;
    const title = filterBlogData.title;
    const description = filterBlogData.description;

    const mailtoLink = `mailto:?subject=${encodeURIComponent(
      title
    )}&body=${encodeURIComponent(`${title}\n${description}\n${shareUrl}`)}`;

    window.location.href = mailtoLink;
  };
  return (
    <>
      {filterBlogData && Object.keys(filterBlogData).length > 0 && (
        <>
          <Helmet>
            <title>{filterBlogData.title}</title>
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="article" />
            <meta property="og:title" content={filterBlogData.title} />
            <meta property="og:quote" content={filterBlogData.title} />{" "}
            {/* Should be og:quote */}
            <meta property="og:hashtag" content="hashtag" />
            <meta property="og:image" content={filterBlogData.image_url} />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />
            <meta content="image/*" property="og:image:type" />
            <meta property="og:url" content={window.location.href} />
            <meta
              property="og:description"
              content={filterBlogData.description}
            />
            {/* Corrected Twitter Card tags */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={filterBlogData.title} />
            <meta
              name="twitter:description"
              content={filterBlogData.description}
            />
            <meta name="twitter:image" content={filterBlogData.image_url} />
          </Helmet>

          {/* <meta property="og:url" content={window.location.href} />
      <meta property="og:type" content="article" />
      <meta property="og:title" content={filterBlogData.title} />
      <meta property="og:description" content={filterBlogData.description} />
      <meta property="og:image" content={filterBlogData.image_url} /> */}
        </>
      )}
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="blog-wrapper">
            <BlogHeader />
            <div className="blog-wrapper-content">
              <div className="blog-container">
                <Container>
                  <Row>
                    <Col xs={12} sm={3}>
                      <div className="fixed-table-content">
                        <Card className="card-container">
                          <Card.Body>
                            <Card.Title className="list-head">
                              Table of contents
                              <div className="border"></div>
                            </Card.Title>
                            <Card.Text className="lists-left">
                              <ul>
                                {tableContent.length &&
                                  tableContent.map((item, index) => {
                                    return (
                                      <>
                                        {item.sub_heading === "" ? null : (
                                          <li key={item.id}>
                                            <a href={`#${item.id}`}>
                                              {item.sub_heading}
                                            </a>
                                          </li>
                                        )}
                                      </>
                                    );
                                  })}
                              </ul>
                            </Card.Text>
                          </Card.Body>
                        </Card>
                        <div className="social-icons">
                          <a
                            href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                              window.location.href
                            )}&quote=${encodeURIComponent(
                              filterBlogData.title
                            )}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src={N4}
                              alt=""
                              width={30}
                              height={30}
                              // onClick={handleFacebookShare}
                            />
                          </a>
                          <a
                            href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(
                              window.location.href
                            )}&text=${encodeURIComponent(
                              filterBlogData.title
                            )}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src={N3}
                              alt=""
                              width={30}
                              height={30}
                              // onClick={handleTwitterShare}
                            />
                          </a>
                          <a
                            href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
                              window.location.href
                            )}&title=${encodeURIComponent(
                              filterBlogData.title
                            )}&summary=${encodeURIComponent(
                              filterBlogData.description
                            )}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src={N}
                              alt=""
                              width={30}
                              height={30}
                              // onClick={handleLinkedinShare}
                            />
                          </a>
                          <a href="#">
                            <img
                              src={N2}
                              alt=""
                              width={30}
                              height={30}
                              onClick={handleEmailShare}
                            />
                          </a>
                        </div>
                      </div>
                    </Col>
                    <Col xs={12} sm={6}>
                      <Card
                        style={{
                          // width: "25rem",
                          marginBottom: "40px",
                          boxShadow: "0 5px 5px 0px rgba(0, 0, 0, 0.25)",
                          border: "2px solid #EEE",
                        }}
                        ref={targetDivRef}
                      >
                        <Card.Img
                          variant="top"
                          src={filterBlogData.image_url}
                          className="card-image"
                          // style={{
                          //   height: "250px",
                          //   overflow: "hidden",
                          //   objectFit: "cover",
                          // }}
                        />
                        <Card.Body>
                          <div className="card-author-container">
                            <p>{filterBlogData.author_name}</p>
                            <p>
                              {" "}
                              {moment(filterBlogData.createdAt).format("ll")}
                            </p>
                          </div>
                          <Card.Title className="card-title">
                            <h1>{filterBlogData.title}</h1>
                          </Card.Title>
                          <Card.Text className="card-desc detail-text">
                            {filterBlogData.description}
                          </Card.Text>
                          {tableContent.length &&
                            tableContent.map((item, index) => {
                              return (
                                <>
                                  <div id={item.id}></div>
                                  <Card.Title className="card-sub-title">
                                    {item.sub_heading === "" ? null : (
                                      <h2>{item.sub_heading}</h2>
                                    )}
                                  </Card.Title>
                                  <Card.Text className="card-desc detail-text">
                                    {/* {parse(item.sub_description)} */}
                                    {/* {item.sub_description} */}
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: item.sub_description,
                                      }}
                                    ></div>
                                  </Card.Text>
                                </>
                              );
                            })}
                          <div className="card-footer-container">
                            <div>
                              <p>Share this article </p>
                            </div>
                            <div className="social-icons">
                              <a
                                href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                                  window.location.href
                                )}&quote=${encodeURIComponent(
                                  filterBlogData.title
                                )}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img
                                  src={N4}
                                  alt=""
                                  width={30}
                                  height={30}
                                  // onClick={handleFacebookShare}
                                />
                              </a>
                              <a
                                href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(
                                  window.location.href
                                )}&text=${encodeURIComponent(
                                  filterBlogData.title
                                )}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img
                                  src={N3}
                                  alt=""
                                  width={30}
                                  height={30}
                                  // onClick={handleTwitterShare}
                                />
                              </a>
                              <a
                                href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
                                  window.location.href
                                )}&title=${encodeURIComponent(
                                  filterBlogData.title
                                )}&summary=${encodeURIComponent(
                                  filterBlogData.description
                                )}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img
                                  src={N}
                                  alt=""
                                  width={30}
                                  height={30}
                                  // onClick={handleLinkedinShare}
                                />
                              </a>
                              <a href="#">
                                <img
                                  src={N2}
                                  alt=""
                                  width={30}
                                  height={30}
                                  onClick={handleEmailShare}
                                />
                              </a>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col xs={12} sm={3}>
                      <Card
                        style={{ border: "none", background: "transparent" }}
                      >
                        <Card.Body>
                          <Card.Title className="list-head">
                            Recent Posts
                            <div className="border"></div>
                          </Card.Title>
                          <Card.Text className="lists">
                            {recentPost.length &&
                              recentPost.map((item, index) => {
                                return (
                                  <p
                                    key={item.id}
                                    onClick={() =>
                                      handleSelectNews(item.blog_id, item.title)
                                    }
                                  >
                                    <span>{">> "}</span>
                                    {item.title}
                                  </p>
                                );
                              })}
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
            <div className="footer">
              <Footer />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Index;
