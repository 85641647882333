import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import PizzaLogoFooter from "../../images/pizzaLogoFooter.svg";
import Facebook from "../../images/facebook.png";
import Instagram from "../../images/instagram.png";

import useWindowResize from "../../hooks/common/useWindowResize";

const Index = () => {
  const { screenWidth, screenHeight } = useWindowResize();
  return (
    <>
      <Container>
        <Row>
          <Col lg="12">
            <div className="footer-box">
              <div className="footer-group">
                <img src={PizzaLogoFooter} alt="footer-logo" />
                {screenWidth < 992 ? null : (
                  <p className="bottom-footer-text copy">
                    Copyright © {new Date().getFullYear()}. All rights reserved
                    by RestoGPT
                  </p>
                )}
              </div>
              {/* {screenWidth < 992 ? (
                  <div className="footer-group-icon">
                    <p>
                      <a href="https://www.facebook.com/pizzaboxai">
                        <img
                          src={Facebook}
                          alt="facebook-icon"
                          className="footer-img"
                        />
                      </a>
                    </p>
                    <p className="bottom-footer-text">
                      <a href="https://www.instagram.com/pizzaboxai/">
                        <img
                          src={Instagram}
                          alt="instagram-icon"
                          className="footer-img"
                        />
                      </a>
                    </p>
                  </div>
                ) : null} */}
              <div className="footer-group">
                <p>
                  <a href="mailto:pizzabox@restogpt.ai">
                  PIZZABOX@RESTOGPT.AI
                  </a>
                </p>
                <p className="bottom-footer-text">Los Angeles, CA</p>
              </div>
              {screenWidth < 992 ? (
                <div className="footer-group">
                  <p>
                    <a href="news">NEWS AND PRESS</a>
                  </p>
                  <p
                    className="bottom-footer-text"
                    // style={{ display: "none" }}
                  >
                    <a href="blog">BLOG</a>
                  </p>
                </div>
              ) : (
                <div className="footer-group">
                  <p>
                    <a href="news">NEWS AND PRESS</a>
                  </p>
                  <p
                    className="bottom-footer-text"
                    // style={{ visibility: "hidden", display: "block" }}
                  >
                    <a href="blog">BLOG</a>
                  </p>
                </div>
              )}

              <div className="footer-group">
                <p>
                  <a href="term-and-condition" target="_blank">
                    TERMS AND CONDITIONS
                  </a>
                </p>
                <p className="bottom-footer-text">
                  <a href="privacy-policy" target="_blank">
                    PRIVACY POLICY
                  </a>
                </p>
              </div>
              {/* {screenWidth < 992 ? null : (
                <div className="footer-group-icon">
                  <p>
                    <a href="https://www.facebook.com/pizzaboxai">
                      <img
                        src={Facebook}
                        alt="facebook-icon"
                        className="footer-img"
                      />
                    </a>
                  </p>
                  <p className="bottom-footer-text">
                    <a href="https://www.instagram.com/pizzaboxai/">
                      <img
                        src={Instagram}
                        alt="instagram-icon"
                        className="footer-img"
                      />
                    </a>
                  </p>
                </div>
              )} */}
              {screenWidth < 992 ? (
                <div className="footer-group">
                  <p className="bottom-footer-text copy">
                    Copyright © {new Date().getFullYear()}. All rights reserved
                    by RestoGPT
                  </p>
                </div>
              ) : null}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Index;
