/* eslint-disable react/jsx-no-duplicate-props */
import React, { useState } from "react";
import { Modal, Row, Col, Button } from "react-bootstrap";
import CloseIcon from "../../images/close-icon.png";
import odr1 from "../../images/popup/order/odr1.png";
import odr2 from "../../images/popup/order/odr2.png";
import odr3 from "../../images/popup/order/odr3.png";
import odr4 from "../../images/popup/order/odr4.png";
import odr5 from "../../images/popup/order/odr5.png";
import odr6 from "../../images/popup/order/odr6.png";
import odr7 from "../../images/popup/order/odr7.png";
import odr8 from "../../images/popup/order/odr8.png";
import odr9 from "../../images/popup/order/odr9.png";
import odr10 from "../../images/popup/order/odr10.png";
import odr11 from "../../images/popup/order/odr11.png";
import odr12 from "../../images/popup/order/odr12.png";
import odr13 from "../../images/popup/order/odr13.png";
import odr14 from "../../images/popup/order/odr14.png";
import odr15 from "../../images/popup/order/odr15.png";
import odr16 from "../../images/popup/order/odr16.png";
import odr17 from "../../images/popup/order/odr17.png";
import Search from "../../images/search.png";
import PizzaLogo from "../../images/pizzaLogo.svg";

function StorefrontModal() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className="more-container">
        <a className="btun" onClick={handleShow}>
          LEARN MORE<img src={Search} alt="Search" />
        </a>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        centered
        className="AI-modal odrdash"
      >
        <Modal.Body>
          <Button className="close-btn" onClick={handleClose}>
            <img src={CloseIcon} />
          </Button>
          <Row>
            <div className="modal-herder-container">
              <div className="model-header">
                <h2 className="header-modal">AI ORDER <span>MANAGEMENT</span></h2>
              </div>
              <div className="modal-header-logo">
                <img src={PizzaLogo} alt="PizzaLogo" />
              </div> 
            </div>
            <p className="header-model-text">[ADVANCED MENU MANAGEMENT SOLUTIONS]</p>
          </Row>
          <Row>
            <Col lg="6" sm="12">
              <div className="item">
                <img src={odr1} />
                <p>
                  Onboarding automation {" "}
                  <span style={{ color: "#FF8700" }}>[live in 24 hours]</span>
                </p>
              </div>
              <div className="item">
                <img src={odr2} />
                <p>
                  Payment processing {" "}
                  <span style={{ color: "#FF8700" }}>[Stripe+]</span>
                </p>
              </div>
              <div className="item">
                <img src={odr3} />
                <p>
                  POS and Kitchen Printer integrations <br />
                  <span style={{ color: "#FF8700" }}>[Toast, Square+]</span>
                </p>
              </div>
              <div className="item">
                <img src={odr5} />
                <p>
                  Delivery dispatch and logic <br />
                  <span style={{ color: "#FF8700" }}>
                    [zone, distance, terms]
                  </span>
                </p>
              </div>
              <div className="item">
                <img src={odr4} />
                <p>
                  Last-mile delivery service provider integration {" "}
                  <span style={{ color: "#FF8700" }}>
                    [1000+ on-demand drivers]
                  </span>
                </p>
              </div>
              <div className="item">
                <img src={odr6} />
                <p>
                  <span style={{ color: "#FF8700" }}>[Own]</span> delivery
                  driver management app
                </p>
              </div>
              <div className="item">
                <img src={odr7} />
                <p>
                  Marketplace integrations <br />
                  <span style={{ color: "#FF8700" }}>
                    [DoorDash, Uber Eats, GrubHub+]
                  </span>
                </p>
              </div>
              <div className="item">
                <img src={odr8} />
                <p>
                  Order consolidation from all channels <br />
                  <span style={{ color: "#FF8700" }}>
                    [Third-party, Direct, Social+]
                  </span>
                </p>
              </div>
            </Col>
            <Col lg="6" sm="12">
              <div className="item">
                <img src={odr17} />
                <p>
                Single dashboard for orders from all channels {" "}
                  <span style={{ color: "#FF8700" }}>
                  [DoorDash, Uber Eats+]
                  </span>
                </p>
              </div>
              <div className="item">
                <img src={odr9} />
                <p>
                  Order management <br />
                  <span style={{ color: "#FF8700" }}>
                    [Pause, 86 item, Notifications+]
                  </span>
                </p>
              </div>
              <div className="item">
                <img src={odr10} />
                <p>Kitchen settings automation</p>
              </div>
              <div className="item">
                <img src={odr11} />
                <p>AI order status automation</p>
              </div>
              <div className="item">
                <img src={odr12} />
                <p>Order history and delivery tracking</p>
              </div>
              <div className="item">
                <img src={odr13} />
                <p>Refund and dispute management</p>
              </div>
              <div className="item">
                <img src={odr14} />
                <p>Financial reports and data analytics</p>
              </div>

              <div className="item">
                <img src={odr15} />
                <p>Price adjustment per channel</p>
              </div>
              <div className="item">
                <img src={odr16} />
                <p>
                  Menu maintenance and monitoring <br />
                  <span style={{ color: "#FF8700" }}>[AI support]</span>
                </p>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default StorefrontModal;
