import React from "react";
import { ArrowRightShort } from "react-bootstrap-icons";

const Number = ({
  phone,
  setPhone,
  handleKeypress,
  current,
  next,
  errorMessage,
  setErrorMessage,
}) => {
  const myFunction = (event) => {
    handleKeypress(event, current, "");
  };

  React.useEffect(() => {
    var phoneFocus = document.getElementById("phoneId");
    phoneFocus.focus();
    // const loadScriptByURL = (id, url, callback) => {
    //   const isScriptExist = document.getElementById(id);

    //   if (!isScriptExist) {
    //     var script = document.createElement("script");
    //     script.type = "text/javascript";
    //     script.src = url;
    //     script.id = id;
    //     script.onload = function () {
    //       if (callback) callback();
    //     };
    //     document.body.appendChild(script);
    //   }

    //   if (isScriptExist && callback) callback();
    // };

    // // load the script by passing the URL
    // loadScriptByURL(
    //   "recaptcha-key",
    //   `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_SITE_KEY}`,
    //   function () {
    //     // console.log("Script loaded!");
    //   }
    // );

    // const keyDownHandler = (event) => {
    //   if (event.key === "Enter") {
    //     event.preventDefault();
    //     myFunction(event);
    //   }
    // };
    // document.addEventListener("keydown", keyDownHandler);
    // return () => {
    //   document.removeEventListener("keydown", keyDownHandler);
    // };
  }, []);

  const handlePhoneChange = (event) => {
    const { value } = event.target;
    if (/^[+0-9 ]*$/.test(value)) {
      setPhone(value);
      // localStorage.setItem("phone", value);
      setErrorMessage("");
    } else {
      setErrorMessage("Please enter a valid phone number.");
    }
  };

  return (
    <>
      <div className="form-box">
        <p>
          <div>
            9
            <ArrowRightShort />
            {/* 9<ArrowRightShort /> */}
            Enter your phone number
          </div>
        </p>
        <p className="comment-le">
          * This number will be used to notify you when your storefront is ready
        </p>
        <div style={{ position: "relative" }}>
          <form action="#" onSubmit={(e) => e.preventDefault()}>
            <label className="phoneLabel">+1</label>
            <input
              type="text"
              id="phoneId"
              className="form-control left"
              style={{ paddingLeft: "50px" }}
              maxLength={10}
              value={phone}
              onChange={handlePhoneChange}
              onKeyPress={(e) => handleKeypress(e, current, next)}
              // onChange={(val) => {
              //   console.log(val.target.value);
              //   if (val.target.value.match("^[+0-9 ]*$") != null) {
              //     setPhone(val.target.value);
              //     localStorage.setItem("phone", val.target.value);
              //   }
              //   setErrorMessage("");
              // }}
            />
          </form>
          <p className="comment-l">{errorMessage}</p>
        </div>
      </div>
    </>
  );
};

export default Number;
