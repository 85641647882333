import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import Sample from "../../images/sample.png";
import Slider1 from "../../images/slider/slider1.png";
import Slider2 from "../../images/slider/slider2.png";
import Slider3 from "../../images/slider/slider3.png";
import Slider4 from "../../images/slider/slider4.png";
import PizzaLogo from "../../images/pizzaLogo.svg";
import Search from "../../images/search.png";

const Index = () => {
  const data = [
    {
      img: Slider1,
      url: "https://restogpt.ai/pacificpizza",
    },
    {
      img: Slider2,
      url: "https://restogpt.ai/cpk/",
    },
    {
      img: Slider3,
      url: "https://restogpt.ai/fresco-pizza-deli",
    },
    {
      img: Slider4,
      url: "https://restogpt.ai/restogpt413291025",
    },
    {
      img: Slider1,
      url: "https://restogpt.ai/pacificpizza",
    },
    {
      img: Slider2,
      url: "https://restogpt.ai/cpk/",
    },
    {
      img: Slider3,
      url: "https://restogpt.ai/fresco-pizza-deli",
    },
    {
      img: Slider4,
      url: "https://restogpt.ai/restogpt413291025",
    },
  ];
  const handletoRestogpt = () => {
    window.open("https://restogpt.ai", "_blank");
  };

  return (
    <>
      <Container>
        <Row>
          <Col lg="12">
            <div className="success-generated-header">
              <h1>
                Pizzerias Love <img src={PizzaLogo} />
              </h1>
              <p>
                Deliver Pizzas Like Big Chains and{" "}
                <span>Keep the Whole Pie</span>
              </p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg="12" className="slider-container">
            <div className="swiper-slider-container">
              <Swiper
                slidesPerView={1}
                spaceBetween={10}
                navigation={true}
                loop={true}
                breakpoints={{
                  600: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  1000: {
                    slidesPerView: 4,
                    spaceBetween: 40,
                  },
                }}
                modules={[Navigation]}
                className="pizza_swiper"
              >
                {data.map((item, index) => {
                  return (
                    <>
                      <SwiperSlide>
                        <div className="item" key={index}>
                          <div className="slider-image">
                            <img src={item.img} />
                          </div>
                          <a
                            href={item.url}
                            target="_blank"
                            rel="noreferrer"
                            className="btun"
                          >
                            VIEW STOREFRONT <img src={Search} alt="Search" />
                          </a>
                        </div>
                      </SwiperSlide>
                    </>
                  );
                })}
              </Swiper>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Index;
