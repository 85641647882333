import HttpClient from "../../../../../utils/httpClient";
import axios from "axios";

const oneFormSubmitData = async (siteName, decodedValue, convertedText) => {
  let formDataOne = JSON.parse(localStorage.getItem("formDataOne"));
  let formDataTwo = JSON.parse(localStorage.getItem("formDataTwo"));

//   let decodeWord = decodedValue !== "" ? decodedValue : siteName;
//   let url = `https://restogpt.ai/${decodeWord}`;
//   let resNamefromUrl = convertedText !== "" ? convertedText : "";
//   let resName =
//     resNamefromUrl !== "" ? resNamefromUrl : formDataOne.restaurantName;

  let sheetName = "zsms018p14yh4";

  const orderPreparationTime =
    formDataTwo.orderPreparationTime === "Other"
      ? formDataTwo.otherOrderPreparationTime + " min"
      : formDataTwo.orderPreparationTime;

  const deliveryCharges =
    formDataTwo.deliveryCharges ===
    "Other (indicate any custom split you’d like)"
      ? formDataTwo.otherDeliveryCharges
      : formDataTwo.deliveryCharges;

  const stripeFee =
    formDataTwo.stripeFee === "Other (indicate any custom split you’d like)"
      ? formDataTwo.otherStripeFee
      : formDataTwo.stripeFee;

  const salesTax =
    formDataTwo.salesTax === "Other"
      ? formDataTwo.otherSalesTax + "%"
      : formDataTwo.salesTax;

  const pos =
    formDataOne.posState === "Other"
      ? formDataOne.otherPosState
      : formDataOne.posState;

  let sheetsData = new FormData();

  sheetsData.append("Customer_Name", formDataOne.name);
  sheetsData.append("Customer_Email", formDataOne.email);
  //   sheetsData.append("Restaurant_Name", formDataOne);
  sheetsData.append("Phone_Number", formDataOne.restaurantNumber);
  sheetsData.append("Restaurant_Address", formDataOne.restaurantAddress1);
  sheetsData.append("Website", formDataOne.restaurantWebsite);
  sheetsData.append("Delivery_Type", formDataOne.deliveryType);
  sheetsData.append("POS_Type", pos);
  //   sheetsData.append("Site", siteNameURl);
  sheetsData.append("Storefront_Domain", formDataOne.storefrontDomain);
  sheetsData.append("Customer_Database", formDataOne.customerDatabase);
  //   sheetsData.append("Data_From", url);

  sheetsData.append("Order_Preparation_Time", orderPreparationTime);
  sheetsData.append("Delivery_Charges", deliveryCharges);
  sheetsData.append("Stripe_Fee", stripeFee);
  sheetsData.append("Sales_Tax", salesTax);
  sheetsData.append("Company_Name", formDataTwo.companyName);
  sheetsData.append("Company_Type", formDataTwo.companyType);
  sheetsData.append("EIN", formDataTwo.ein);
  sheetsData.append("DBA", formDataTwo.dba);
  sheetsData.append("PIZZA_BOX", "YES");

  axios
    .post(`https://sheetdb.io/api/v1/${sheetName}`, sheetsData)
    .then((response) => {
      console.log("response", response);
      if (response) {
        // localStorage.clear();
        localStorage.removeItem("clientSecret");
        localStorage.removeItem("formDataOne");
        localStorage.removeItem("siteName");
        localStorage.removeItem("formDataTwo");
      }
    });
};

export default oneFormSubmitData;
