import React, { useState } from "react";
import { Modal, Row, Col, Button } from "react-bootstrap";
import CloseIcon from "../../images/close-icon.png";
import crm1 from "../../images/popup/crm/crm1.png";
import crm2 from "../../images/popup/crm/crm2.png";
import crm3 from "../../images/popup/crm/crm3.png";
import crm4 from "../../images/popup/crm/crm4.png";
import crm5 from "../../images/popup/crm/crm5.png";
import crm6 from "../../images/popup/crm/crm6.png";
import crm7 from "../../images/popup/crm/crm7.png";
import crm8 from "../../images/popup/crm/crm8.png";
import crm9 from "../../images/popup/crm/crm9.png";
import Search from "../../images/search.png";

import PizzaLogo from "../../images/pizzaLogo.svg";

function StorefrontModal() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className="more-container">
        <a className="btun" onClick={handleShow}>
          LEARN MORE
          <img src={Search} alt="Search" />
        </a>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        centered
        className="AI-modal lastmile"
      >
        <Modal.Body>
          <Button className="close-btn" onClick={handleClose}>
            <img src={CloseIcon} />
          </Button>
          <Row>
            <div className="modal-herder-container">
              <div className="model-header">
                <h2 className="header-modal">DATA MARKETING <br /><span>AUTOMATION</span></h2>
              </div>
              <div className="modal-header-logo">
                <img src={PizzaLogo} alt="PizzaLogo" />
              </div> 
            </div>
            <p className="header-model-text">[DATA-DRIVEN CUSTOMER ENGAGEMENT CAMPAIGNS]</p>
          </Row>
          <Row>
            <Col lg="6" sm="12">
              <div className="item">
                <img src={crm1} />
                <p>
                  Customer database{" "}
                  <span style={{ color: "#FF8700" }}>[structured]</span>
                </p>
              </div>
              <div className="item">
                <img src={crm2} />
                <p>
                  Review management{" "}
                  <span style={{ color: "#FF8700" }}>[AI]</span>
                </p>
              </div>
              <div className="item">
                <img src={crm3} />
                <p>
                  CRM <span style={{ color: "#FF8700" }}>[segmented data]</span>
                </p>
              </div>
              <div className="item">
                <img src={crm4} />
                <p>Loyalty, marketing, remarketing tools</p>
              </div>
              <div className="item">
                <img src={crm5} />
                <p>Email and SMS automation</p>
              </div>
            </Col>

            <Col lg="6" sm="12">
              <div className="item">
                <img src={crm6} />
                <p>Campaign content template editor</p>
              </div>
              <div className="item">
                <img src={crm7} />
                <p>Customer engagement tools</p>
              </div>
              <div className="item">
                <img src={crm8} />
                <p>Automated order review requests</p>
              </div>
              <div className="item">
                <img src={crm9} />
                <p>Promo and QR code generator</p>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default StorefrontModal;
