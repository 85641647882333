import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Modal, Row, Col, Button } from "react-bootstrap";
import ModalTick from "../../../images/modalTick.png";
import CloseIcon from "../../../images/Close.png";
import Tick from "../../../images/tick.png";
import Rocket from "../../../images/roket.png";

const LaunchModal = ({ open, handleClose }) => {
  const navigate = useNavigate();
  let formDataOne = JSON.parse(localStorage.getItem("formDataOne"));
  let formDataTwo = JSON.parse(localStorage.getItem("formDataTwo"));
  let storefrontUrlName = localStorage.getItem("storefront_url_name");
  const [clientSecret, setClientSecret] = useState("");
  const [loading, setLoading] = useState(false);

  const handelProceedToPay = () => {
    fetchPaymentIntent();
  };

  const fetchPaymentIntent = async () => {
    setLoading(true);
    const url =
      "https://orderbyte.io/api/subscription/v1/payment/create-payment-intent";
      const urlLocal = "https://orderbyte.io/api/subscription/v1/payment/create-payment-intent-local"
    const headers = {
      auth: "order_xht4sAytlopr8hn_byte",
      "Content-Type": "application/json",
    };
    const orderPreparationTime =
      formDataTwo.orderPreparationTime === "Other"
        ? formDataTwo.otherOrderPreparationTime + " min"
        : formDataTwo.orderPreparationTime;

    const deliveryCharges =
      formDataTwo.deliveryCharges ===
      "Other (indicate any custom split you’d like)"
        ? formDataTwo.otherDeliveryCharges
        : formDataTwo.deliveryCharges;

    const stripeFee =
      formDataTwo.stripeFee === "Other (indicate any custom split you’d like)"
        ? formDataTwo.otherStripeFee
        : formDataTwo.stripeFee;

    const salesTax =
      formDataTwo.salesTax === "Other"
        ? formDataTwo.otherSalesTax + "%"
        : formDataTwo.salesTax;

    const pos =
      formDataOne.posState === "Other"
        ? formDataOne.otherPosState
        : formDataOne.posState;
    const data = {
      name: formDataOne.name,
      email: formDataOne.email,
      url: formDataOne.restaurantWebsite,
      phone_number: "+1" + formDataOne.restaurantNumber,
      restaurant_address: formDataOne.restaurantAddress1,
      storefront_domain: formDataOne.storefrontDomain,
      delivery_type: formDataOne.deliveryType,
      pos_type: pos,
      order_preparation_time: orderPreparationTime,
      delivery_charges: deliveryCharges,
      stripe_fee: stripeFee,
      sales_tax: salesTax,
      company_name: formDataTwo.companyName,
      company_type: formDataTwo.companyType,
      ein: formDataTwo.ein,
      dba: formDataTwo.dba,
      storefront_url_name: storefrontUrlName,
      status: "started",
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(data),
      });

      if (!response) {
        throw new Error("Failed to create payment intent");
      }

      const result = await response.json();
      // console.log("Payment intent created:", result);
      setClientSecret(result.clientSecret);
      if (result && result.clientSecret) {
        localStorage.setItem("clientSecret", result.clientSecret);
        setTimeout(() => {
          setLoading(false);
          navigate("/checkout");
        }, 1000);
      }
      // Handle the payment intent response here
    } catch (error) {
      console.error("Error creating payment intent:", error);
      // Handle the error here
    }
  };

  return (
    <>
      <Modal
        show={open}
        onHide={handleClose}
        size="lg"
        centered
        className="Launch-modal"
      >
        <Modal.Body>
          <Button className="close-btn" onClick={handleClose}>
            <img src={CloseIcon} />
          </Button>
          <div className="modal-head">
            <h1>Start 30-Day Trial </h1>
            <p>no commitment</p>
          </div>
          <div className="blue-part">
            <h1>So, What’s Left?</h1>
            <div className="stepper-modal">
              <div class="step-modal">
                <div className="step-left">
                  <span class="step-number-modal">1</span>
                  <div class="step-content-modal">Fill out the form</div>
                </div>
                <div className="step-right">
                  <span class="step-round-modal">
                    <img src={ModalTick} alt="modal-tick" />
                  </span>
                </div>
              </div>
              <div class="divider"></div>
              <div class="step-modal">
                <div className="step-left">
                  <span class="step-number-modal">2</span>
                  <div class="step-content-modal">Pay the $1 setup fee</div>
                </div>
                <div className="step-right">
                  <span class="step-round-modal"></span>
                </div>
              </div>
              <div class="divider"></div>
              <div class="step-modal">
                <div className="step-left">
                  <span class="step-number-modal">3</span>
                  <div class="step-content-modal">
                    Start 30-day no commitment trial
                  </div>
                </div>
                <div className="step-right">
                  <span class="step-round-modal"></span>
                </div>
              </div>
            </div>
            <div className="trail-section">
              <div className="trail-container">
                <div className="trail-content">
                  <div className="trail-price-modal">
                    <div className="price-box-modal">
                      <img src={Tick} alt="tick" />
                      <p>No Monthly Fee</p>
                    </div>
                    <div className="price-box-modal">
                      <img src={Tick} alt="tick" />
                      <p>No Hidden Fees</p>
                    </div>
                    <div className="price-box-modal">
                      <img src={Tick} alt="tick" />
                      <p>No Contract</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-btn-container">
              {loading ? (
                <button className="modal-btn">
                  <div className="spinner" id="spinner"></div>
                </button>
              ) : (
                <button className="modal-btn" onClick={handelProceedToPay}>
                  Last step to launch <img src={Rocket} alt="rocket" />
                </button>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LaunchModal;
