import React from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import InputHints from "react-input-hints";

class LocationSearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { address: "" };
  }

  componentDidMount() {
    var restaurantAddressFocus = document.getElementById("restaurantAddress");
    restaurantAddressFocus.focus();
  }

  handleChange = (address) => {
    this.setState({ address });

    this.props.callback(address);
    this.props.setErrorMessage("");
  };

 handleKeypress = (e) => {
  if (e.key === "Enter" && this.props.isRestaurantAddressSelected) {
    const obj = {key: e.key}
    this.props.handleKeypress(e,this.props.current, this.props.next)
  }
 }

  handleSelect = (address,e) => {
    geocodeByAddress(address)
      .then((results) => {
       // getLatLng(results[0]);

        const { lat, lng } = results[0].geometry.location;
        console.log(lat(), lng());
        this.props.setLocation(results[0])
        this.props.callback(address);
        this.props.setErrorMessage("");
        this.props.setIsRestaurantAddressSelected(true);
        this.setState({ address });
      })
      .then((latLng) => {
        // console.log("Success", latLng)
    })
      .catch((error) => {
        // console.error("Error", error)
      });
  };

  render() {
    const center = { lat: 50.064192, lng: -130.605469 };
    const defaultBounds = {
      north: center.lat + 0.1,
      south: center.lat - 0.1,
      east: center.lng + 0.1,
      west: center.lng - 0.1,
    };
    const searchOptions = {
      bounds: defaultBounds,
      componentRestrictions: { country: "us" },
      fields: ["address_components", "geometry", "icon", "name"],
      strictBounds: false,
      types: ["address"],
    };
    return (
      <PlacesAutocomplete
        value={this.state.address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}      
        searchOptions={searchOptions}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <InputHints
              {...getInputProps({
                // placeholder:this.props.placeholder,
                // className:this.props.className,
              })}
              id="restaurantAddress"
              type="text"
              value={this.props.restaurantAddress}
              placeholders={[this.props.placeholder]}
              className={this.props.className}
              onKeyDown={this.handleKeypress}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div style={{ color: "white" }}>Loading...</div>}
              {suggestions.map((suggestion) => {
                const className = suggestion.active
                  ? "suggestion-item--active"
                  : "suggestion-item";
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? {
                      backgroundColor: "rgba(255,137,0)",
                      cursor: "pointer",
                      padding: "5px",
                      color: "#fff",
                      fontSize: "14px",
                    }
                  : {
                      backgroundColor: "rgba(255,137,0)",
                      cursor: "pointer",
                      padding: "5px",
                      color: "#fff",
                      fontSize: "14px",
                    };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                    key={suggestion.description}
                  >
                    <span style={{color: "black"}}>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}

export default LocationSearchInput;
