import React from "react";
import { Container, Row, Col, Nav, Navbar } from "react-bootstrap";
import Logo from "../images/pizzaLogo.svg";
import Footer from "../components/Footer/index";
import "./condition.css";

const Index = () => {
  const handleOpenPizzaSubscription = () => {
    window.open("http://subscription.pizzabox.ai", "_blank")
  }
  return (
    <>
      <div className="policy-wrapper">
        <div className="header headerSolid">
          <Navbar collapseOnSelect expand="lg">
            <Container fluid className="justify-content-between">
              <Navbar.Brand href="/" className="logo">
                <img src={Logo} />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse
                id="basic-navbar-nav"
                className="justify-content-center center"
              >
                <Nav className="menu">
                  <Nav.Link
                    href="https://pizzabox.ai/#WHYPIZZABOX"
                    className="nav-link dot"
                  >
                    WHY PIZZABOX
                  </Nav.Link>
                  <Nav.Link
                    href="https://pizzabox.ai/#PIZZERIAS"
                    className="nav-link dot"
                  >
                    PIZZERIAS
                  </Nav.Link>
                  <Nav.Link
                    href="https://pizzabox.ai/#WHATSINTHEBOX"
                    className="nav-link dot"
                  >
                    WHAT'S IN THE BOX
                  </Nav.Link>
                  <Nav.Link
                    href="https://pizzabox.ai/#PRICING"
                    className="nav-link dot"
                  >
                    PRICING
                  </Nav.Link>
                  <Nav.Link
                    href=""
                    className="nav-link dot"
                    onClick={handleOpenPizzaSubscription}
                  >
                    PIZZA SUBSCRIPTIONS
                  </Nav.Link>
                  <Nav.Link
                    href="https://pizzabox.ai/#PARTNERWITHUS"
                    className="gen-btn"
                  >
                    PARTNER WITH US
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
        <section className="main-content">
          <Container>
            <Row>
              <Col sm={12}>
                <div className="py-5">
                  <h4>TERMS AND CONDITIONS</h4>
                  <p>
                    CloudBrands, Inc. (”PizzaBox,” “Orderbyte,” “Localbytes,”
                    “we,” “us,” or “our”) writes these Terms and Conditions
                    (”Terms”) as they relate to the products and services
                    offered by us. We appreciate your interest in our services,
                    which are composed of our PizzaBox ordering platform
                    (“PizzaBox”), web-based digital storefronts built by
                    PizzaBox for our licensees’ (the “Restaurants”) use, native
                    restaurant apps, and our ”Localbytes” marketplaces platform
                    (“Marketplace”), which are accessed via our dedicated
                    websites,{" "}
                    <a href="https://www.localbytes.co/_" target="_blank">
                      (http://www.localbytes.co/_)
                    </a>{" "}
                    and{" "}
                    <a href="https://pizzabox.ai/" target="_blank">
                      (https://pizzabox.ai/)
                    </a>
                    . The PizzaBox ordering platform, web-based digital
                    storefronts, native restaurant apps, and Marketplace
                    platforms are collectively referred to herein as the
                    “Platform.” The activities enabled via the PizzaBox ordering
                    platform, web-based digital storefronts, native restaurant
                    apps, and Marketplace platforms together comprise, and are
                    collectively referred to as, the “Services.” As “Localbytes”
                    belongs under the umbrella of products offered by
                    “PizzaBox,” it will be considered as one when “PizzaBox” is
                    mentioned in the terms below.
                  </p>
                  <p>
                    We provide our Services to you subject to the following
                    Terms and Conditions, which we reserve the rights to update
                    and modify at any time, as we improve the service, by
                    posting a modified version directly here on this Site. By
                    browsing the public areas or by accessing and using the
                    Services, you acknowledge that you have read, understood,
                    and agree to be legally bound by the terms and conditions of
                    our Terms and Conditions and Privacy Policy, which is hereby
                    incorporated by reference (collectively, “Agreement.”). If
                    you do not agree to any of these terms, then please do not
                    use the Services.
                  </p>
                  <p>
                    Three user types exist on the Platform and Services. These
                    include: Food service establishments and related partners
                    (each individually, a “Restaurant”), individuals ordering
                    from said Restaurants (referred to as “Customer”), and
                    property owners and real estate management firms that
                    operate a PizzaBox-enabled food delivery marketplace in
                    their property (”Building”). By registering as a user of
                    this Website (“User,” “Restaurant,” “Customer,” “Building,”
                    “you,” or “your”) or by placing an Order through this
                    Website, you agree to be bound to these Terms (as defined
                    below).
                  </p>
                  <p>
                    By using the service, you indicate your acceptance of these
                    terms and your agreement to be bound by these terms. You may
                    not use the service if you do not agree to these terms.
                    Please carefully read the agreement to understand each
                    provision. There are mandatory class action and individual
                    arbitration waiver provisions that affect your legal rights.
                    If you use the platform and/or its services, either as an
                    individual or on behalf of a company, you agree to bind both
                    the “individual” and the “company” to the terms.
                  </p>
                  <h4>a. Platform and Services Description</h4>
                  <p>
                    PizzaBox is a restaurant order management system for takeout
                    and delivery, featuring a digital workforce that converts a
                    restaurant's menu into an operational delivery storefront.
                    This digital worker, powered by AI, manages and delivers all
                    orders without the need for new staff, drivers, or
                    additional technology. It provides a single dashboard for
                    independent restaurants to launch and grow their entire
                    online ordering operation in one place. Our single dashboard
                    equips restaurants and dark kitchens with digital tools to
                    manage and maximize their online sales: branded digital
                    storefronts, omnichannel ordering, multi-menu management,
                    hyperlocal marketplaces, personalized virtual restaurant
                    brands, and more.
                  </p>
                  <p>
                    Although you are able to place orders through the Services,
                    PizzaBox itself does not sell the products contained in your
                    order, is not a party to any such transaction, and has no
                    control over the quality or safety of the products. Your
                    order, including an order placed through Marketplace, is
                    between you and the restaurant from which you order. In
                    addition, restaurants may state ingredients or represent
                    allergen or food preparation standards through the Services,
                    such as food or beverages being nut-free, gluten-free,
                    lactose-free, organic, or kosher. We do not investigate or
                    verify the menus, ingredients, food preparation standards,
                    or any descriptions, statements, or representations made by
                    the restaurants. Therefore, if you or anyone else who will
                    be consuming any item from an order has any food allergies
                    or specific preparation requirements, you are strongly
                    advised to contact the restaurant directly to address your
                    specific needs. By using the Services, you agree that
                    PizzaBox is not responsible for any statements or omissions
                    concerning the products contained in your order.
                  </p>
                  <p>
                    Also, please be advised that your use of Google Pay or Apple
                    Pay in connection with the Services is subject to the terms
                    and conditions, including the privacy policies, of Google
                    and Apple, respectively. By using Google Pay, you hereby
                    accept the Google Pay API Terms of Service. PizzaBox uses
                    Google Maps and the Google Maps API as part of our online
                    ordering system. By using PizzaBox web, iOS, or Android
                    ordering systems, you are also bound to the Google Maps API
                    Terms of Service.
                  </p>
                  <p>
                    We provide Visitors and Registered Users with access to the
                    Services as described in this Agreement. Visitors. No login
                    is required for visitors to the Website (“Visitors”).
                    Visitors can (a) view all publicly-accessible content, (b)
                    e-mail us, and (c) chat with us. Registered Users. Login is
                    required for all individuals who register to use the
                    Services (“Registered Users”). Registered Users can do all
                    the things Visitors can do and also (a) place orders, (b)
                    search for restaurants based on location and cuisine, (c)
                    research a particular restaurant, (d) sign up for alerts and
                    other notifications, and (e) blog about your dining
                    experience, including posting to your Facebook wall.
                    PizzaBox is under no obligation to accept any individual as
                    a Registered User or a Member and may accept or reject any
                    registration in its sole and complete discretion.
                  </p>
                  <h4>b. User Conduct and Guidelines</h4>
                  <p>
                    The PizzaBox community, like any community, functions best
                    when its people follow a few simple rules. By accessing
                    and/or using the Services, you hereby agree to comply with
                    these community rules and that:
                  </p>
                  <ol>
                    <li>
                      You agree to use the Platform in a manner that is
                      consistent with these Terms and compliance with all
                      applicable laws and regulations;
                    </li>
                    <li>
                      By becoming a registered User, you consent to the
                      inclusion of your data in our database, including all
                      orders requested through PizzaBox-enabled digital
                      storefront;
                    </li>
                    <li>
                      You will not use, duplicate, copy, reproduce, sell, resell
                      or exploit the Platform or Services for any unlawful
                      purpose, including any fraudulent activity, or to engage
                      in any commercial activities, including, without
                      limitation, raising money; advertising or promoting a
                      product, service, or company; or engaging in any
                      multi-tiered marketing scheme;
                    </li>
                    <li>
                      You will not access or use the Services to collect any
                      market research for competing businesses. This includes
                      reverse engineering, disassembling or attempting to
                      discover the source code or underlying structures,
                      know-how, algorithms pertaining to the Platform or
                      Service;
                    </li>
                    <li>
                      You acknowledge and agree to not upload, post, transmit,
                      or perform any other sharing action of PizzaBox content
                      (sound, graphics, photographs, text, pictures, any other
                      materials pertaining to Restaurants, advertisers, partners
                      on PizzaBox) that infringes on any protected copyrights,
                      trademarks, service marks, patents, or any proprietary
                      rights of people/entities. You are only permitted to share
                      what is expressly authorized by us or our partners, based
                      on the specific content piece;
                    </li>
                    <li>
                      You will not impersonate any person or entity or falsely
                      state or otherwise misrepresent your affiliation with a
                      person or entity;
                    </li>
                    <li>
                      You will not use manual or automated means (such as robot,
                      spider, crawlers, data mining tools, site retrieval
                      applications, among others) to scrape or index data from
                      the Platform, directly or indirectly, except for Internet
                      search engines (e.g., Google) and non-commercial public
                      archives (e.g., archive.org) that comply with our
                      guidance;
                    </li>
                    <li>
                      You will not create multiple accounts for yourself for any
                      reason, including, without limitation, in order to obtain
                      the same promotion multiple times;
                    </li>
                    <li>
                      You will not interfere with or attempt to interrupt or
                      impair the Platform or Service operations via any virus,
                      device, information collection or transmission mechanism,
                      software or routine, or access or attempt to gain access
                      to any data, files, or passwords related to the Services
                      through hacking, password or data mining, or any other
                      means; and
                    </li>
                    <li>
                      You agree to comply with all local, state, provincial,
                      country and/or regional laws or regulations that are
                      applicable to your use of the Platform.
                    </li>
                  </ol>
                  <p>
                    We reserve the right, in our sole discretion, to deny,
                    suspend or terminate you (or any device or IP address) and
                    your account access to the Services, or any portion of the
                    Services, without notice and/or reason. A violation of any
                    of the guidelines may result in CloudBrands, Inc. taking
                    legal action and/or implementing appropriate technical
                    remedies for prevention.
                  </p>
                  <h4>c. Eligibility and Restrictions</h4>
                  <p>
                    As an eligible "Customer," you affirm that you are an
                    individual aged 13 years or older. Your use of the Service
                    must comply with all applicable Terms. The services are
                    tailored for Diners in the United States, where the Platform
                    and Service are provided. If you are aged 13 or older but
                    under 18, it is advisable to review this Agreement with your
                    parent or guardian to ensure a comprehensive understanding.
                    The Services may not be used for purchasing alcohol products
                    unless you and the recipient meet the minimum age
                    requirements dictated by applicable state or provincial
                    laws. Valid photo identification verifying your age is
                    required at the time of order pickup or delivery.
                  </p>
                  <p>
                    As an eligible "Restaurant," you assert that you are an
                    individual aged 18 years or older, complying with all
                    applicable Terms. The platform and services are specifically
                    designed for restaurants in the United States, where the
                    Platform and Service are provided. Restaurants utilizing our
                    Services are explicitly prohibited from selling or offering
                    for sale any tobacco or nicotine products, including but not
                    limited to cigarettes and e-cigarettes, to any customer,
                    regardless of age. Attempts to purchase such products
                    through our Services are strictly forbidden. We reserve the
                    right to deny you access to our Services without notice if,
                    in our sole discretion, we determine that you have violated
                    or attempted to violate this policy. Regardless of your
                    legal age to purchase tobacco products in your jurisdiction,
                    attempting to do so through our Services is expressly
                    prohibited. PizzaBox enforces a maximum order amount of
                    $1,000 per order, with the right to modify this maximum at
                    any time without notice.
                  </p>
                  <h4>d. Registration</h4>
                  <p>
                    During the registration process, Users are required to
                    create an account, providing essential information,
                    including but not limited to:
                  </p>
                  <ol>
                    <li>Full name</li>
                    <li>Email address</li>
                    <li>Unique username</li>
                    <li>Unique password</li>
                    <li>Unique identifier</li>
                    <li>
                      Further information to assist in authenticating user
                      identity
                    </li>
                  </ol>
                  <p>
                    It is mandatory to furnish accurate, truthful, and
                    up-to-date information during the registration process.
                    Users bear the responsibility for maintaining the
                    confidentiality and security of their private account
                    details. In the event of any breach or unauthorized use,
                    Users acknowledge their obligation to promptly inform
                    CloudBrands, Inc. We reserve the right to modify any user
                    details without notice at any time. CloudBrands, Inc.
                    assumes no responsibility for any loss incurred due to the
                    unauthorized breach of a user's account.
                  </p>
                  <p>
                    Certain features of the Platform or Services may be subject
                    to their own terms and conditions, requiring agreement
                    during the sign-up process for those specific products,
                    functions, or services. All other terms and conditions
                    outlined in each agreement will remain in full force and
                    effect.
                  </p>
                  <h4>e. Pickup and Deliveries</h4>
                  <p>
                    Restaurants accessible through the Services may not provide
                    delivery to every location. If your area is not currently
                    serviced, but you wish to avail our delivery service, kindly
                    inform us. As we continue to expand our service coverage, we
                    recommend creating an account for the latest updates.
                  </p>
                  <p>
                    For deliveries within the United States, we engage
                    third-party delivery companies. Should you be unavailable at
                    the delivery location, the delivery personnel will leave the
                    package at your door, with the doorman, or at the designated
                    delivery center, with applicable fees charged for the order.
                    Receipt of the delivery by anyone at the delivery address is
                    conclusively presumed as authorized. In cases of inclement
                    weather, deliveries will be made as soon as reasonably
                    possible when conditions permit.
                  </p>
                  <p>
                    Certain PizzaBox Restaurant locations may offer takeout or
                    pickup as an alternative to delivery, referred to as
                    "Pickup." If you choose Pickup on the Platform during the
                    ordering process, your order will be delivered to your car
                    at the selected Restaurant location. Pickup services are
                    provided by the Restaurants themselves, excluding
                    involvement from PizzaBox or any third-party delivery
                    services. All terms and conditions pertaining to delivery
                    orders, including refunds and returns, apply equally to
                    Pickup orders.
                  </p>
                  <p>
                    If you opt for Pickup, it is essential to inform the
                    Restaurant upon your arrival. You may be required to provide
                    specific information about your vehicle, such as your
                    license plate number and/or the make and model of your car.
                    Please refer to our Privacy Policy for comprehensive details
                    on how we collect and process such information.
                  </p>
                  <p>
                    PizzaBox, distinct from the restaurant, delivery service, or
                    its agent, explicitly disclaims any express or implied
                    warranty concerning the delivery or pickup services and
                    denies any such warranties that may otherwise exist.
                  </p>
                  <h4>f. Payments </h4>
                  <p>
                    Payment for orders through the Services is facilitated by
                    PizzaBox. PizzaBox provides the capability for card-based
                    transactions (or other approved methods) to process payments
                    for any order, establishing an account for you on the
                    Platform. By utilizing the Services, you acknowledge and
                    authorize PizzaBox to promptly authenticate your credit card
                    (or other approved payment method) and confirm your status
                    as an authorized user of the selected payment method.
                  </p>
                  <p>
                    Fees and taxes will be applied to each order. Additionally,
                    when utilizing the Marketplace for placing orders, RestoGPT
                    AI may impose an additional fee, referred to as a "Local
                    impact fee," which could vary based on the order. Payment
                    processing is conducted through PizzaBox's certified payment
                    processor, including Stripe Inc. ("Stripe"). All payment
                    transactions are subject to Stripe's "Terms of Service." By
                    proceeding with payment, you are thereby bound by their
                    Service Agreement, subject to modifications by Stripe
                    without prior notice.
                  </p>
                  <p>
                    You are required to maintain strict confidentiality
                    regarding your Password. You are accountable for all
                    activities and charges associated with your Password. Your
                    responsibility for such charges remains in effect even after
                    the termination of this Agreement.
                  </p>
                  <p>
                    In the event of any unsuccessful charges, PizzaBox will
                    notify you of the failure to proceed with the order or
                    transaction through a rejection window screen or an
                    automated email. Should you have inquiries regarding a
                    transaction on your credit card statement, please utilize
                    the "Contact Us" section available on the Platform or
                    Service to reach our support team. All refund processes
                    adhere to the refund policies set by the respective
                    restaurants from which you place orders. Requests for
                    refunds directed to us cannot be processed until we receive
                    approval from the applicable restaurant. We will make
                    reasonable efforts to initiate and acquire refunds when
                    deemed appropriate. To clarify, if a full refund is granted
                    for an order made through the Marketplace, it will encompass
                    the Local impact fee. However, in the case of a partial
                    refund for a Marketplace order, the refund will not include
                    the Local impact fee.
                  </p>
                  <h4>g. Copyright and Intellectual Property </h4>
                  <p>
                    The Platform and Services encompass a variety of materials,
                    including software, data, information, text, graphics,
                    images, sound recordings, audiovisual works, and other
                    content provided by or on behalf of PizzaBox (collectively
                    referred to as the “Content”). By registering or using the
                    Platform or Services, you acknowledge and grant us a
                    royalty-free, worldwide, non-exclusive, irrevocable,
                    perpetual, and transferable right and license to utilize,
                    modify, publicly perform, reproduce, create derivative works
                    from, copy, sublicense, and develop the content you provide
                    to us, at any point in time, both now and in the future, for
                    any purpose or no specific reason. However, you retain all
                    copyrights and relevant intellectual property rights to any
                    content you post on the Platform or Services. We reserve the
                    sole discretion to decide whether to display any Content on
                    our Platform or Services and may delete or remove any
                    Content at any time, for any or no reason.
                  </p>
                  <p>
                    We are committed to complying with all applicable copyright
                    laws and expect the same from all Users of the Platform or
                    Services. The Content may be owned by us or third parties
                    and is protected under both United States and foreign laws.
                    Unauthorized use of the Content may violate copyright,
                    trademark, and other laws. You do not possess any rights to
                    the Content, and you agree not to use the Content except as
                    permitted under this Agreement. No other use is allowed
                    without prior expressed written permission from us. You must
                    maintain all copyright and other proprietary notices
                    included in the original Content on any copy you make. You
                    are prohibited from selling, transferring, assigning,
                    licensing, sublicensing, modifying, reproducing, displaying,
                    publicly performing, creating derivative versions of,
                    distributing, or using the Content in any manner for any
                    public or commercial purpose. By transmitting Content
                    through our Platform or Services, you warrant that: 1) you
                    own or have legal authorization to use the Content, and 2)
                    the use of such content does not violate any legal terms,
                    including any injury or perjury to individuals or entities.
                    Using or posting the Content on any other website or in a
                    networked computer environment for any purpose is expressly
                    forbidden.
                  </p>
                  <p>
                    Failure to comply with any part of this Agreement terminates
                    your permission to access and/or use the Content and
                    Services, and you must immediately destroy any copies of the
                    Content that you have made.
                  </p>
                  <p>
                    The trademarks, service marks, and logos of PizzaBox
                    ("PizzaBox Trademarks") utilized and displayed on the
                    Services are registered and unregistered trademarks or
                    service marks of CloudBrands, Inc. Other company, product,
                    and service names found on the Services may be trademarks or
                    service marks owned by others (referred to as the
                    “Third-Party Trademarks,” collectively with PizzaBox
                    Trademarks as the “Trademarks”). Nothing on the Services
                    should be interpreted as granting, by implication, estoppel,
                    or otherwise, any license or right to use the Trademarks
                    without our prior written permission for each specific use.
                    Using the Trademarks as part of a link to or from any site
                    is prohibited unless expressly approved by us in writing
                    beforehand. All benefits derived from the use of PizzaBox
                    Trademarks accrue to our benefit.
                  </p>
                  <p>
                    Elements of the Services are protected by trade dress,
                    trademark, unfair competition, and other state and federal
                    laws and may not be copied or imitated, in whole or in part,
                    through any means, including but not limited to the use of
                    framing or mirrors. The Content may not be retransmitted
                    without our express, written consent for each instance.
                  </p>
                  <p>
                    While we encourage you to email us, please refrain from
                    emailing any content containing confidential information.
                    With regard to all emails you send to us, including
                    feedback, questions, comments, suggestions, and similar
                    content, we reserve the right to use any ideas, concepts,
                    know-how, or techniques contained in your communications for
                    any purpose, including but not limited to, the development,
                    production, and marketing of products and services
                    incorporating such information.
                  </p>
                  <p>
                    PizzaBox respects the intellectual property rights of others
                    and endeavors to comply with all applicable laws. We will
                    review all claims of copyright infringement received and
                    remove any Content or user submissions that are deemed to
                    have been posted or distributed in violation of such laws.
                    For receipt of any Notification of Claimed Infringement
                    under the Digital Millennium Copyright Act, please provide
                    the following information to hello@restogpt-ai.com:
                  </p>
                  <ol>
                    <li>
                      An electronic or physical signature of the person
                      authorized to act on behalf of the owner of the copyright
                      interest;
                    </li>
                    <li>
                      A description of the copyrighted work that you claim has
                      been infringed;
                    </li>
                    <li>
                      Details of where the allegedly infringing material is
                      located on the Website;
                    </li>
                    <li>Your address, telephone number, and email address;</li>
                    <li>
                      A statement by you that you have a good faith belief that
                      the disputed use is not authorized by the copyright owner,
                      its agent, or the law;
                    </li>
                    <li>
                      A statement by you, made under penalty of perjury, that
                      the above information in your notification is accurate and
                      that you are the copyright owner or authorized to act on
                      the copyright owner’s behalf.
                    </li>
                  </ol>
                  <h4>h. Limitation of Liability</h4>
                  <p>
                    CloudBrands, Inc., its officers, employees, and affiliated
                    partners, under warranty, common law tort, or contract
                    claims, shall not be liable for consequential, incidental,
                    special, direct, punitive, or indirect damages, including
                    but not limited to lost profits or other losses. These
                    include damages related to lost data, business interruption,
                    over-reliance, or inability to access or use the Platform,
                    Services, or Content, regardless of whether the possibility
                    of damages has been advised. Moreover, direct damages not
                    associated with personal injuries resulting from the use of
                    the Services or the Content shall be limited to the amount
                    paid to CloudBrands, Inc. within the three (3) months
                    immediately preceding the events leading to the claim. Our
                    aggregate liability concerning these terms or the use of our
                    Platform or Services (including warranty claims) will not
                    exceed either the fees paid to CloudBrands, Inc. in the
                    three months prior to the claim or $120.
                  </p>
                  <p>
                    While ensuring customer satisfaction is paramount, any
                    issues with your food order, including delivery services,
                    should be addressed directly with the respective restaurant.
                    The contractual agreement for your order is solely between
                    you and the restaurant. PizzaBox operates solely as a
                    conduit for facilitating the order and is not a direct party
                    to such transactions.
                  </p>
                  <p>
                    The Content and Services are provided on an "as is" and "as
                    available" basis without any warranties of any kind.
                    CloudBrands, Inc. disclaims all warranties, including but
                    not limited to the warranty of title, merchantability,
                    non-infringement of third-party rights, fitness for a
                    particular purpose, and any warranties arising from a course
                    of dealing, course of performance, or usage of trade. We do
                    not endorse the content of advertisements or third-party
                    content, nor do we assume any responsibility or liability
                    regarding the accuracy of materials or potential
                    infringement of third-party IP rights, or any resulting
                    fraudulent activities. Users are solely responsible for
                    their User Content and its consequences, recognizing that
                    PizzaBox acts only as a conduit for online distribution and
                    publication of such content.
                  </p>
                  <p>
                    Certain jurisdictions, such as the state of New Jersey, may
                    not permit the exclusion of specific warranties. Therefore,
                    some limitations on warranties in this section may not apply
                    to you. These terms of use do not affect any non-waivable
                    statutory rights applicable to you. The Services and/or the
                    Content may contain technical inaccuracies, typographical
                    errors, or omissions, including but not limited to allergy
                    information or food preparation standards. CloudBrands, Inc.
                    bears no liability for such typographical, technical,
                    pricing, or other errors listed on or omitted from the
                    Services and/or the Content. Information regarding
                    participating restaurants' products may not be available in
                    every location. A reference to a product on the Services or
                    in the Content does not imply its availability in your
                    location. CloudBrands, Inc. reserves the right to make
                    changes, corrections, and improvements to the Services and
                    the Content without prior notice.
                  </p>
                  <p>
                    CloudBrands, Inc. reserves the right to cancel or modify an
                    order if fraudulent or inappropriate activity is suspected
                    or under other circumstances where the order appears to
                    contain or result from a mistake or error. Additionally, we
                    retain the right to report any fraudulent or inappropriate
                    conduct to relevant authorities at our discretion.
                  </p>
                  <h4>i. Indemnification</h4>
                  <p>
                    You agree to indemnify, hold harmless, and defend
                    CloudBrands, Inc., along with our officers, directors,
                    employees, licensees, and Restaurants, against any and all
                    claims, actions, or demands, including reasonable legal and
                    accounting fees, arising from your breach of this Agreement
                    or your misuse of the Content or the Services. This includes
                    allegations, losses, and liabilities. We will promptly
                    notify you of any such claim, suit, or proceeding and will
                    assist you, at your expense, in defending against it.
                    Consequently, you will indemnify PizzaBox and be responsible
                    for any damages, attorney fees awarded against PizzaBox in
                    any claim, as well as all out-of-pocket costs incurred by
                    PizzaBox in defense of a claim and the total settlement
                    amount agreed to be paid to a third-party.
                  </p>
                  <p>
                    We reserve the right to assume the exclusive defense and
                    control of any matter subject to indemnification under this
                    section. In such instances, you agree to cooperate with any
                    reasonable requests assisting our defense of the matter.
                    Additionally, we retain the right, as previously stated, to
                    restrict, modify, deactivate, or terminate your access to
                    PizzaBox's Platform or Services without prior notice. In the
                    event of dissatisfaction with our decision, your sole
                    recourse is to discontinue using PizzaBox.
                  </p>
                  <h4>j. Termination Terms</h4>
                  <p>
                    These Terms remain effective for the duration of your use or
                    access to PizzaBox's Platform or Services. PizzaBox reserves
                    the sole discretion to restrict, suspend, or terminate this
                    Agreement, as well as your and any device’s access to all or
                    any part of the Services, at any time without cause and
                    without prior notice or liability. Additionally, PizzaBox
                    reserves the right to modify, suspend, or discontinue all or
                    any part of the Services without prior notice or liability.
                  </p>
                  <p>
                    Upon termination, you will lose access to PizzaBox's
                    Platform or Services, and any stored information may be
                    deleted.
                  </p>
                  <h4>k. Third-Party Links</h4>
                  <p>
                    PizzaBox's Services may contain links to third-party
                    websites ("Links") solely for convenience. The inclusion of
                    these Links does not imply endorsement or acceptance of
                    responsibility for the content on those sites. Such Links
                    and their content are provided by others. Should you have
                    concerns regarding these Links or their content, please
                    contact the site administrator or webmaster directly. We
                    disclaim responsibility for the content of any Links and do
                    not make representations regarding their accuracy.
                  </p>
                  <p>
                    Exercise caution when downloading files from any website to
                    safeguard your computer against viruses or other destructive
                    programs. Accessing Links is done at your own risk. The use
                    of content or trademarks from PizzaBox for establishing
                    links on your site is not permitted.
                  </p>
                  <h4>l. Arbitration</h4>
                  <p>
                    In resolving any dispute arising from these Terms, the
                    Services, or any other products or services provided by us
                    ("Dispute"), either party may elect to resolve the matter
                    through binding arbitration in accordance with the American
                    Arbitration Association (AAA). To initiate arbitration,
                    contact us at hello@restogpt-ai.com. The decision to
                    arbitrate will be final and binding on both parties.
                    Disputes will be resolved by a neutral arbitrator under the
                    AAA's rules, with enforcement of the arbitrator’s award
                    possible in any court in Los Angeles, California. The
                    arbitration process will be administered by the Judicial
                    Arbitration and Mediation Services (JAMS) according to its
                    applicable rules. The arbitration may occur in person,
                    through document submissions, by phone, or online.
                    Litigation may occur in court for specific actions while
                    arbitration is pending. Please carefully read this section
                    as it mandates arbitration for dispute resolution and
                    outlines limitations on seeking relief from PizzaBox. By
                    opting for arbitration, both parties waive the right to
                    litigate the claim in court or have a jury trial. There
                    might also be limitations on discovery and appeal rights.
                  </p>
                  <h4>m. Class Action and Jury Trial Waiver</h4>
                  <p>
                    Any arbitration or proceeding shall exclusively concern the
                    individual Dispute between PizzaBox and you. No arbitration
                    or proceeding will be consolidated with others or resolved
                    on a class action basis. Additionally, no Dispute will be
                    brought in a representative capacity on behalf of the
                    general public or any other persons. By agreeing to these
                    terms, you waive the right to trial by jury or participation
                    in a class action, collective action, private attorney
                    general action, or any representative proceeding.
                  </p>
                  <h4>n. Compliance</h4>
                  <p>
                    PizzaBox's Services are situated in the United States. We do
                    not warrant whether the Content is suitable for download,
                    viewing, or use outside the United States. Accessing the
                    Services or Content from outside the United States is at
                    your own risk. Irrespective of your location, it is your
                    sole responsibility to ensure compliance with the laws of
                    your jurisdiction, including pertinent federal, provincial,
                    state, and local laws, guidelines, directives, governmental
                    requisites, and regulations.
                  </p>
                  <h4>o. General</h4>
                  <p>
                    You explicitly acknowledge and agree that this Agreement is
                    established in the State of California. This Agreement is to
                    be governed by and interpreted in accordance with the
                    domestic laws of the State of California, excluding its
                    conflict of law principles. The termination of this
                    Agreement, as outlined in the Termination provision above,
                    does not affect the validity of the subsequent provisions of
                    this Agreement (Terms and Conditions), which shall remain
                    fully enforceable. This Agreement constitutes the
                    comprehensive and exclusive understanding between RestoGPT
                    AI and you concerning the Platform or Services, superseding
                    any other agreements or proposals, whether oral or written
                    (including information on the Site), and any prior
                    communications between PizzaBox and you.
                  </p>
                  <p>
                    Our failure to act upon or enforce any provision of this
                    Agreement shall not be construed as a waiver of that
                    provision or any other provision herein. No waiver shall be
                    binding on us unless executed in writing, and no such waiver
                    shall be construed as a waiver in any other or subsequent
                    instance. Except as expressly agreed upon in writing by
                    PizzaBox and you, this Agreement constitutes the entire
                    Agreement between you and us regarding the subject matter,
                    supplanting all previous or contemporaneous agreements,
                    whether written or oral, between the parties on the subject
                    matter. The section headings are provided for convenience
                    only and shall not hold legal significance. This Agreement
                    shall benefit our successors, assigns, licensees, and
                    sublicensees.
                  </p>
                  <p>
                    Copyright © {new Date().getFullYear()} CloudBrands, Inc. All
                    rights reserved.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <div className="footer">
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Index;
