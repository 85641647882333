import React from "react";
import { useNavigate } from "react-router-dom";
import HttpClient from "../utils/httpClient";
import moment from "moment";
import BlogHeader from "./blogHead";
import { Container, Row, Col, Nav, Navbar } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Footer from "../components/Footer/index";
import Loader from "../components/Loader/Loader";
import Timer from "../images/timer.svg";
import DumyImg from "../images/dumy.png";
import { blogData } from "./blogData";
import "./blog.css";

const Index = () => {
  const navigate = useNavigate();
  const [allBlogData, setAllBlogData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const handleContinue = (id, title) => {
    let modifiedTitle = title.replace(/[^a-zA-Z0-9_]/g, "-");
    navigate(`/blog-detail/${id}/${modifiedTitle}`);
  };

  React.useEffect(() => {
    getBlogData();
  }, []);

  const getBlogData = async () => {
    setLoading(true);
    let result = await HttpClient.requestDataBlog(
      "get?auth=b3JkZXJfYmxvZ19zZXJ2aWNlX2FwaV9ieXRl",
      "GET"
    );
    console.log("blog data ", result);
    if (result) {
      const sortedBlogPosts = result.data.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      const filter = sortedBlogPosts.filter((item) => item.isPizzaBox === true);
      setLoading(false);
      setAllBlogData(filter);
    }
  };

  function truncateDescription(description, limit) {
    const words = description.split(" ");
    if (words.length > limit) {
      return words.slice(0, limit).join(" ") + "...";
    } else {
      return description;
    }
  }

  function truncateTitle(title, limit) {
    const words = title.split(" ");
    if (words.length > limit) {
      return words.slice(0, limit).join(" ") + "...";
    } else {
      return title;
    }
  }

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="blog-wrapper">
            <BlogHeader />
            <div className="blog-wrapper-content">
              <div className="blog-container">
                <Container>
                  <Row>
                    {allBlogData.length &&
                      allBlogData.map((item, index) => {
                        return (
                          <>
                            <Col
                              xs={12}
                              sm={4}
                              style={{ marginBottom: "28px" }}
                            >
                              <Card
                                style={{
                                  // width: "25rem",
                                  // marginBottom: "40px",
                                  boxShadow:
                                    "0 5px 5px 0px rgba(0, 0, 0, 0.25)",
                                  border: "2px solid #EEE",
                                  height: "100%",
                                }}
                                key={item.id}
                              >
                                <Card.Img
                                  variant="top"
                                  src={
                                    item.image_url ? item.image_url : DumyImg
                                  }
                                  className="card-image-head"
                                />
                                <Card.Body>
                                  <Card.Title className="card-title detail-text-blog">
                                    <h1>
                                      {item.title &&
                                        truncateTitle(item.title, 10)}
                                    </h1>
                                  </Card.Title>
                                  <Card.Text className="card-desc detail-text-blog">
                                    {/* {item.description} */}
                                    {item.description &&
                                      truncateDescription(item.description, 25)}
                                  </Card.Text>
                                </Card.Body>
                                <Card.Footer
                                  style={{
                                    background: "#EEE",
                                    borderTop: "1px solid #EEE",
                                  }}
                                >
                                  <div className="footer-container">
                                    <span className="footer-text">
                                      {item.how_long} mins read
                                    </span>
                                    <div className="card-time">
                                      <img src={Timer} alt="timer-icon" />
                                      <span className="footer-text">
                                        {moment(item.createdAt).format("ll")}
                                      </span>
                                    </div>
                                    <button
                                      className="footer-btn"
                                      onClick={() =>
                                        handleContinue(item.id, item.title)
                                      }
                                    >
                                      Continue Reading
                                    </button>
                                  </div>
                                </Card.Footer>
                              </Card>
                            </Col>
                          </>
                        );
                      })}
                  </Row>
                </Container>
              </div>
            </div>
            <div className="footer">
              <Footer />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Index;
