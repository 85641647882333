import React, { useState } from "react";
import { Modal, Row, Col, Button } from "react-bootstrap";
import CloseIcon from "../../images/close-icon.png";
import web1 from "../../images/popup/website/web1.png";
import web2 from "../../images/popup/website/web2.png";
import web3 from "../../images/popup/website/web3.png";
import web4 from "../../images/popup/website/web4.png";
import web5 from "../../images/popup/website/web5.png";
import web6 from "../../images/popup/website/web6.png";
import web7 from "../../images/popup/website/web7.png";
import web8 from "../../images/popup/website/web8.png";
import web9 from "../../images/popup/website/web9.png";
import web10 from "../../images/popup/website/web10.png";
import web11 from "../../images/popup/website/web11.png";
import Search from "../../images/search.png";

import PizzaLogo from "../../images/pizzaLogo.svg";

function StorefrontModal() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className="more-container">
        <a className="btun" onClick={handleShow}>
          LEARN MORE<img src={Search} alt="Search" />
        </a>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        centered
        className="AI-modal lastmile"
      >
        <Modal.Body>
          <Button className="close-btn" onClick={handleClose}>
            <img src={CloseIcon} />
          </Button>
          <Row>
            <div className="modal-herder-container">
              <div className="model-header">
                <h2 className="header-modal"><span>WEBSITE</span>, STOREFRONT <br />AND  DISCOVERY</h2>
              </div>
              <div className="modal-header-logo">
                <img src={PizzaLogo} alt="PizzaLogo" />
              </div> 
            </div>
            <p className="header-model-text">[CATERING + ORDERING SOLUTIONS]</p>
          </Row>
          <Row>
            <Col lg="6" sm="12">
              <div className="item">
                <img src={web1} />
                <p>Branded website</p>
              </div>
              <div className="item">
                <img src={web2} />
                <p>Direct ordering storefront web app– no downloads</p>
              </div>
              <div className="item">
                <img src={web3} />
                <p>
                  Own <span style={{ color: "#FF8700" }}>[URL]</span> domain and
                  website hosting
                </p>
              </div>
              <div className="item">
                <img src={web11} />
                <p>
                  Catering storefront web app - no downloads{" "}
                  <span className="try-here">
                    <a
                      href="https://catering.planchatacos.com/order"
                      target="_blank"
                    >
                      SEE HERE
                    </a>
                  </span>
                </p>
              </div>
              <div className="item">
                <img src={web4} />
                <p>
                  Storefront visibility tools <br />
                  <span style={{ color: "#FF8700" }}>
                    [QR codes, link, widgets, pop-ups]
                  </span>
                </p>
              </div>
              <div className="item">
                <img src={web5} />
                <p>
                  Direct ordering channels <br />
                  <span style={{ color: "#FF8700" }}>
                    [Google, Yelp, social media]
                  </span>
                </p>
              </div>
            </Col>
            <Col lg="6" sm="12">
            <div className="item">
                <img src={web7} />
                <p>Promo, upselling and engagement tools</p>
              </div>
              <div className="item">
                <img src={web6} />
                <p>
                  Marketplace ordering channels <br />
                  <span style={{ color: "#FF8700" }}>
                    [DoorDash, Uber Eats, GrubHub+]
                  </span>
                </p>
              </div>
              <div className="item">
                <img src={web8} />
                <p>
                Hyperlocal discovery marketplaces <br />
                  <span className="try-here">
                    <a href="tel:650-899-6522">SEE HERE</a>
                  </span>
                </p>
              </div>
              <div className="item">
                <img src={web8} />
                <p>
                  AI phone answering automation <br />
                  <span className="try-here">
                    <a href="tel:650-899-6522">CALL HERE</a>
                  </span>
                </p>
              </div>
              <div className="item">
                <img src={web9} />
                <p>
                  AI Voice and Chat ordering <br />
                  <span className="try-here">
                    <a
                      href="https://restogpt.ai/restogpt413291025/order"
                      target="_blank"
                    >
                      TRY HERE
                    </a>
                  </span>
                </p>
              </div>
              <div className="item">
                <img src={web10} />
                <p>
                  AI Text ordering {" "}
                  <span className="try-here">
                    <a href="sms:415-818-1594">TEXT HERE</a>
                  </span>
                </p>
              </div>
            </Col>
          </Row>
          {/* <Row>
            <Col lg="12">
              <div className="item">
                <img src={web1} />
                <p>Branded website</p>
              </div>
            </Col>
            <Col lg="12">
              <div className="item">
                <img src={web2} />
                <p>Direct ordering storefront web app– no downloads</p>
              </div>
            </Col>
            <Col lg="12">
              <div className="item">
                <img src={web11} />
                <p>
                  Catering storefront web app - no downloads <br />
                  <span className="try-here">
                    <a
                      href="https://catering.planchatacos.com/order"
                      target="_blank"
                    >
                      SEE HERE
                    </a>
                  </span>
                </p>
              </div>
            </Col>
            <Col lg="12">
              <div className="item">
                <img src={web3} />
                <p>
                  Own <span style={{ color: "#FF8700" }}>[URL]</span> domain and
                  website hosting
                </p>
              </div>
            </Col>
            <Col lg="12">
              <div className="item">
                <img src={web4} />
                <p>
                  Storefront visibility tools <br />
                  <span style={{ color: "#FF8700" }}>
                    [QR codes, link, widgets, pop-ups]
                  </span>
                </p>
              </div>
            </Col>
            <Col lg="12">
              <div className="item">
                <img src={web5} />
                <p>
                  Direct ordering channels <br />
                  <span style={{ color: "#FF8700" }}>
                    [Google, Yelp, social media]
                  </span>
                </p>
              </div>
            </Col>
            <Col lg="12">
              <div className="item">
                <img src={web6} />
                <p>
                  Marketplace ordering channels <br />
                  <span style={{ color: "#FF8700" }}>
                    [DoorDash, Uber Eats, GrubHub+]
                  </span>
                </p>
              </div>
            </Col>
            <Col lg="12">
              <div className="item">
                <img src={web7} />
                <p>Promo, upselling and engagement tools</p>
              </div>
            </Col>
            <Col lg="12">
              <div className="item">
                <img src={web8} />
                <p>
                  AI phone answering automation <br />
                  <span className="try-here">
                    <a href="tel:650-899-6522">CALL HERE</a>
                  </span>
                </p>
              </div>
            </Col>
            <Col lg="12">
              <div className="item">
                <img src={web9} />
                <p>
                  AI Voice and Chat ordering <br />
                  <span className="try-here">
                    <a
                      href="https://restogpt.ai/restogpt413291025/"
                      target="_blank"
                    >
                      TRY HERE
                    </a>
                  </span>
                </p>
              </div>
            </Col>
            <Col lg="12">
              <div className="item">
                <img src={web10} />
                <p>
                  AI Text ordering <br />
                  <span className="try-here">
                    <a href="sms:415-818-1594">TEXT HERE</a>
                  </span>
                </p>
              </div>
            </Col>
          </Row> */}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default StorefrontModal;
