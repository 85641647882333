import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import oneFormSubmitData from "../../hooks/onbording/user/new/oneFormSubmitData";

export default function CheckoutForm() {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      console.log("paymentIntent", paymentIntent);
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
      if (paymentIntent && paymentIntent.status === "succeeded") {
        localStorage.setItem("paymentIntent", paymentIntent.id)
        // sendDataToOurServer(paymentIntent.id, "completed");
      } else {
        // sendDataToOurServer(paymentIntent.id, "failed");
      }
    });
  }, [stripe]);

  const sendDataToOurServer = async (paymentIntentId, statusValue) => {
    const url = "https://orderbyte.io/api/subscription/v1/payment/create";
    const headers = {
      auth: "order_xht4sAytlopr8hn_byte",
      "Content-Type": "application/json",
    };
    const data = {
      id: paymentIntentId,
      status: statusValue,
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(data),
      });

      if (!response) {
        throw new Error("Failed to create payment");
      }

      const result = await response.json();
      console.log("Payment created:", result);
      // Handle the payment intent response here
    } catch (error) {
      console.error("Error creating payment intent:", error);
      // Handle the error here
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    oneFormSubmitData();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);
    console.log("stripe", stripe);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        // return_url: "http://localhost:3000/checkout",
        // return_url: "http://localhost:3000/buffer",
        return_url: "https://pizzabox.ai/buffer",
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.

    console.log("error", error);
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }

    if (error) {
      sendDataToOurServer(error.payment_intent.id, "failed");
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs",
  };

  return (
    <>
      <div className="checkout-container">
        <form className="payment-form" onSubmit={handleSubmit}>
          <div className="text-container">
            <div><p className="setup-text">Setup fee</p></div>
            <div><p className="setup-price">$1.00</p></div>
          </div>
          <PaymentElement
            id="payment-element"
            options={paymentElementOptions}
          />
          <button
            disabled={isLoading || !stripe || !elements}
            id="submit"
            className="checkout-btn"
          >
            <span id="button-text">
              {isLoading ? (
                <div className="spinner" id="spinner"></div>
              ) : (
                "Pay $1.00"
              )}
            </span>
          </button>
          {/* Show any error or success messages */}
          {message && <div id="payment-message">{message}</div>}
        </form>
      </div>
    </>
  );
}
