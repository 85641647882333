import React from "react";
import { carousal } from "../../hooks/onbording/static/carousal";
import RestoCarousel from "../common/RestoCarousel";
import Carousel from "react-bootstrap/Carousel";
import MailIcon from "../../../images/mail-icon.png";
import MobileLogo from "../../../images/mobile-logo.png";
import { Container, Row, Col } from "react-bootstrap";
import PizzaTop from "../../../images/pizzaTop.png"

const Index = () => {
  return (
    <>
      <div className="mobile-top-container">
      <img src={PizzaTop} alt="PizzaTop" />
        <Container>
          <Row>
            <Col>
              <div className="mobile-headerr">
                <h3 className="mobile-onbheader">So, What’s Next?</h3>
                <div className="mobile-stepper-modal">
                  <div class="mobile-step-modal">
                    <div className="mobile-step-left">
                      <span class="mobile-step-number-modal">1</span>
                      <div class="mobile-step-content-modal">
                        Fill out the form
                      </div>
                    </div>
                  </div>
                  <div class="mobile-divider"></div>
                  <div class="mobile-step-modal">
                    <div className="mobile-step-left">
                      <span class="mobile-step-number-modal">2</span>
                      <div class="mobile-step-content-modal">
                        Pay the $1 setup fee
                      </div>
                    </div>
                  </div>
                  <div class="mobile-divider"></div>
                  <div class="mobile-step-modal">
                    <div className="mobile-step-left">
                      <span class="mobile-step-number-modal">3</span>
                      <div class="mobile-step-content-modal">
                        Start 30-day, no commitment trial
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Index;
