import React from "react";
import InputHints from "react-input-hints";
import { ArrowRightShort } from "react-bootstrap-icons";

const Name = ({
  fullName,
  setFullName,
  errorMessage,
  setErrorMessage,
  handleKeypress,
  current,
  next,
}) => {
  React.useEffect(() => {
    var fullNameFocus = document.getElementById("fullName");
    fullNameFocus.focus();
  }, []);
  return (
    <>
      <div className="form-box">
        <p>
          <div>
            7<ArrowRightShort />
            {/* 7<ArrowRightShort /> */}
            Enter your full name<span>*</span>
          </div>
        </p>
        <form action="#" onSubmit={(e) => e.preventDefault()}>
          <InputHints
            type="text"
            id="fullName"
            placeholders={["e.g. Robert Cooperman"]}
            className="form-control"
            value={fullName}
            onChange={(val) => {
              setFullName(val.target.value);
              setErrorMessage("");
            }}
            onKeyPress={(e) => handleKeypress(e, current, next)}
          />
        </form>
        <p className="comment-l">{errorMessage}</p>
        {/* <p className="comment">* This question is required</p> */}
      </div>
    </>
  );
};

export default Name;
