import React from "react";
import { Container, Row, Col, Nav, Navbar } from "react-bootstrap";
import Logo from "../images/pizzaLogo.svg";
import Footer from "../components/Footer/index";
import "./policy.css";

const Index = () => {
  const handleOpenPizzaSubscription = () => {
    window.open("http://subscription.pizzabox.ai", "_blank")
  }
  return (
    <>
      {/* <iframe
        src="https://velectico.top/orderbyte_website/privacypolicy.html"
        title="Privacy Policy"
        style={{
          width: "100%",
          height: "800px",
          border: "none",
        }}
      /> */}
      <div className="policy-wrapper">
        <div className="header headerSolid">
          <Navbar collapseOnSelect expand="lg">
            <Container fluid className="justify-content-between">
              <Navbar.Brand href="/" className="logo">
                <img src={Logo} />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse
                id="basic-navbar-nav"
                className="justify-content-center center"
              >
                <Nav className="menu">
                  <Nav.Link
                    href="https://pizzabox.ai/#WHYPIZZABOX"
                    className="nav-link dot"
                  >
                    WHY PIZZABOX
                  </Nav.Link>
                  <Nav.Link
                    href="https://pizzabox.ai/#PIZZERIAS"
                    className="nav-link dot"
                  >
                    PIZZERIAS
                  </Nav.Link>
                  <Nav.Link
                    href="https://pizzabox.ai/#WHATSINTHEBOX"
                    className="nav-link dot"
                  >
                    WHAT'S IN THE BOX
                  </Nav.Link>
                  <Nav.Link
                    href="https://pizzabox.ai/#PRICING"
                    className="nav-link dot"
                  >
                    PRICING
                  </Nav.Link>
                  <Nav.Link
                    href=""
                    className="nav-link dot"
                    onClick={handleOpenPizzaSubscription}
                  >
                    PIZZA SUBSCRIPTIONS
                  </Nav.Link>
                  <Nav.Link
                    href="https://pizzabox.ai/#PARTNERWITHUS"
                    className="gen-btn"
                  >
                    PARTNER WITH US
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
        <section className="main-content">
          <Container>
            <Row>
              <Col sm={12}>
                <div className="py-5">
                  <h4>PRIVACY POLICY</h4>
                  <p>
                    CloudBrands, Inc. (”PizzaBox,” ”Orderbyte,” “Localbytes”
                    “we,” “us,” or “our”) are committed to the protection of
                    your privacy. This privacy policy ("Policy") outlines the
                    collection, use, sharing, storage, transfer, and protection
                    (collectively, "Processing") of your Personal Information.
                    This document delineates why and how we process your
                    Personal Information, as detailed in the "About this Policy"
                    section. We periodically update this Policy to reflect
                    evolving practices.
                  </p>
                  <h4>About this Policy</h4>
                  <p>
                    This Policy delineates the processing of Personal
                    Information by PizzaBox, its customers (the
                    "Establishments"), in relation to food orders placed through
                    online ordering platforms and associated products and
                    services offered by PizzaBox (the "Services"). Additionally,
                    it outlines how we process your Personal Information on
                    behalf of Establishments, such as administering loyalty
                    programs or providing email marketing services for sending
                    Messages. If Establishments process your Personal
                    Information for other purposes, we encourage you to directly
                    engage with them to address your rights or gain insights
                    into their Processing practices.
                  </p>
                  <p>
                    This Policy also elucidates how we process your Personal
                    Information when you engage with our website, mobile
                    application, and other platforms collectively termed as the
                    "PizzaBox Platforms." This encompasses information gathered
                    through Cookies. For further details, please refer to the
                    "How does PizzaBox use Cookies?" section.
                  </p>
                  <h4>What is personal information?</h4>
                  <p>
                    For the context of this Policy, “Personal Information”
                    refers to any data about an identifiable individual,
                    encompassing information that alone or combined with other
                    data identifies, contacts, or locates a single person. In
                    general, it excludes business contact information, such as
                    name, title, or business contact details.
                  </p>
                  <h4>What personal information do we collect?</h4>
                  <p>
                    The Personal Information that we collect may include, but is
                    not limited to:
                  </p>
                  <ul>
                    <li>
                      Contact information (e.g., your name, address, email and
                      phone number, username and passwords of your account, if
                      any);
                    </li>

                    <li>Date of birth;</li>
                    <li>Social media accounts;</li>
                    <li>Survey feedback;</li>
                    <li>
                      Technical information (e.g., information about your mobile
                      and/or computer devices, GPS location or the unique
                      identifier of your device, the transactions you make).
                    </li>
                  </ul>
                  {/* <p>
                    We may also collect details that you have provided in your
                    food order that indicate any allergies or food intolerance
                    that you may have.
                  </p> */}
                  <h4>How do we collect your personal information?</h4>
                  <p>
                    Directly from you <br />
                    Primarily, we gather the necessary Personal Information
                    directly from you, such as when utilizing the Service,
                    browsing PizzaBox Platforms, visiting an Establishment’s
                    website, or engaging in communication with us. We may also
                    collect data generated by your mobile/computer devices, like
                    mobile advertising identifiers, location information, and IP
                    addresses. Refer to the "How does PizzaBox provide online
                    advertising?" section for more details.
                  </p>
                  {/* <p>
                    With a legal basis, such as your consent The legal basis to
                    Process your Personal Information is typically because you
                    have consented to it, unless applicable laws permit another
                    applicable legal basis (e.g., for the purpose of complying
                    with our legal obligations, where necessary for
                    establishing, exercising, or defending a legal claim, a
                    prospective legal claim, legal proceedings or prospective
                    legal proceedings.).
                  </p>
                  <p>
                    Refusal of collection and withdrawal of your consent You
                    have the right, if you so choose, to refuse the Processing
                    of your Personal Information. You may also, at any time, and
                    subject to reasonable notice and applicable legal or
                    contractual restrictions, withdraw your consent (if any) to
                    the Processing of your Personal Information in our
                    possession by contacting us. You should be aware, however,
                    that if you choose not to provide your Personal Information,
                    this may prevent you, for example, from using the Service,
                    as this information is essential to the provision of such
                    Service. You may contact ORDERBYTE as set forth in the
                    Section “How to contact us?” to address any issue thereof.
                  </p> */}
                  <h4>Legal Basis or Consent</h4>
                  <p>
                    Processing your Personal Information typically relies on
                    your consent unless other applicable laws permit alternative
                    legal grounds (e.g., compliance with legal obligations,
                    establishment, exercise, or defense of legal claims, ongoing
                    or prospective legal proceedings).
                  </p>
                  <p>
                    Refusal of Collection and Withdrawal of Consent You retain
                    the right to refuse the Processing of your Personal
                    Information. Similarly, you can, at any time and subject to
                    reasonable notice and relevant legal or contractual
                    restrictions, withdraw your consent (if granted) to the
                    Processing of your Personal Information held by us. However,
                    opting not to provide certain Personal Information may
                    impede your ability to utilize the Service, as this data is
                    crucial for its provision. Contact PizzaBox, as detailed in
                    the "How to contact us?" section, to address any concerns or
                    queries regarding this matter.
                  </p>
                  <h4>Why do we collect your personal information?</h4>
                  <p>
                    For specific and limited Purposes ORDERBYTE generally
                    collects your Personal Information to:
                  </p>
                  <ul>
                    <li>
                      Enable you to order products from an Establishment,
                      confirm successful orders, track the closest Establishment
                      to your location, and facilitate product delivery if
                      applicable, as part of providing you with the Service.
                    </li>
                    <li>Respond to your queries or questions.</li>
                    <li>Authenticate account users.</li>
                    <li>
                      Provide advertising while using the Service, PizzaBox
                      Platforms, Messages, or third-party services, including
                      social media.
                    </li>
                    <li>
                      Offer service and product recommendations based on your
                      preferences.
                    </li>
                    <li>Administer surveys you've agreed to complete.</li>
                    <li>Administer Establishment loyalty programs.</li>
                    <li>
                      Send Messages and manage email campaigns on behalf of the
                      Establishments (collectively, the “Purposes”).
                    </li>
                  </ul>
                  <h4>Sending Messages</h4>
                  <p>
                    If you consent to receive information, PizzaBox may send
                    newsletters, electronic messages, news on new
                    features/products, updates, contests, special offers, and
                    other written communications (“Messages”) about PizzaBox
                    services/products or third-party products/services,
                    including the Establishments. Messages may be sent by
                    PizzaBox or on behalf of the Establishments. Contact the
                    Establishments to address your rights or learn more about
                    how they Process your Personal Information. PizzaBox might
                    also use Direct Push and geographical information on the
                    mobile application to send alerts about local promotions.
                  </p>
                  <p>
                    You can opt-out of receiving Messages per the “How to
                    contact us?” section or by following instructions in the
                    Messages. You may also disable Direct Push notices via your
                    device settings. A ten-day processing period may apply.
                    However, PizzaBox may continue sending Messages about
                    available upgrades, critical technical service issues, or
                    important account information.
                  </p>
                  {/* <p>
                    For secondary purposes <br />
                    ORDERBYTE may also aggregate and anonymize Personal
                    Information that you have provided to create statistical
                    data which ORDERBYTE may use to improve the Service, examine
                    trends and interests, train machine learning algorithms, or
                    share with its third-party partners including the
                    Establishments, advertising partners and research centres.
                    Such statistical data does not include any Personal
                    Information.
                  </p> */}
                  <h4>Secondary Purposes</h4>
                  <p>
                    PizzaBox may aggregate and anonymize Personal Information
                    provided to create statistical data used to enhance the
                    Service, examine trends, train machine learning algorithms,
                    or share with third-party partners, including
                    Establishments, advertising partners, and research centers.
                    Such statistical data excludes Personal Information.
                  </p>
                  <h4>Who do we share your Personal Information with?</h4>
                  <p>
                    PizzaBox does not rent or sell your Personal Information to
                    third parties and shares it only as necessary by law or for
                    specific Purposes.
                  </p>
                  <h4>With our Employees</h4>
                  <p>
                    Employees access your Personal Information solely for
                    work-related purposes, such as when you contact us or
                    request support, limiting access to what's necessary for
                    their duties.
                  </p>
                  <h4>With Strategic Partners</h4>
                  <p>
                    PizzaBox may share your Personal Information with partners
                    like Establishments as required for the Purposes.
                    Third-party services, including payment processors, delivery
                    companies, hosting services, loyalty program providers, and
                    customer support contractors, might access your Personal
                    Information for specific business operations.
                  </p>
                  <h4>When Required by Applicable Laws</h4>
                  <p>
                    PizzaBox may share your Personal Information when required
                    by law or in good faith to:
                  </p>
                  <ul>
                    <li>Comply with legal obligations or judicial orders.</li>
                    <li>Protect and defend PizzaBox's rights or property.</li>
                    <li>Verify your compliance with agreements.</li>
                    <li>Prevent fraud or illegal activity via the Service.</li>
                    <li>Safeguard personal safety or public interests.</li>
                  </ul>
                  <h4>During Business Transfer</h4>
                  <p>
                    Sharing your Personal Information might occur during
                    business operations such as mergers, acquisitions,
                    bankruptcies, or asset sales, as part of due diligence to
                    relevant third parties. Other Purposes with Your Consent
                    Where you expressly consent, your Personal Information might
                    be shared with third parties, stipulated by additional terms
                    to clarify specific third-party involvement in Processing
                    your Personal Information.
                  </p>
                  <h4>Third-Party Services and Platforms</h4>
                  <p>
                    The PizzaBox Platforms might include links to third-party
                    sites or services not controlled by PizzaBox, like
                    integrated features from Google or social media (Facebook,
                    Twitter, LinkedIn, etc.). Your activities on these platforms
                    may be displayed on social media. Read the privacy policies
                    of these third-party sites or platforms to understand their
                    practices. This Policy applies solely to Personal
                    Information Processed by PizzaBox or on its behalf. How long
                    will we keep your Personal Information?
                  </p>
                  <p>
                    PizzaBox will retain your Personal Information only for the
                    duration necessary to fulfill the Purposes for which it was
                    collected or as required to serve PizzaBox's legitimate
                    interests, or to comply with applicable legal, tax, or
                    regulatory requirements. Following this period, any Personal
                    Information held by PizzaBox will be securely destroyed,
                    deleted, or rendered anonymous. For further information on
                    this topic, please contact us as outlined in the "How to
                    contact us?" section.
                  </p>
                  <h4>How do we protect your Personal Information?</h4>
                  <h4>Ensuring Necessary and Appropriate Security Measures</h4>
                  <p>
                    PizzaBox has implemented security measures to safeguard your
                    Personal Information. The specific security protocols
                    employed are contingent on the type of information
                    collected. However, PizzaBox employs physical, electronic,
                    and procedural safeguards that adhere to applicable
                    regulations to protect your Personal Information. We
                    strongly encourage caution when using the internet,
                    including refraining from sharing passwords. If you suspect
                    an unauthorized account has been created using your details,
                    please contact us as detailed in the "How to contact us?"
                    section.
                  </p>
                  <p>
                    Increase your level of privacy
                    <br />
                    You maintain control over the tools on your mobile devices.
                    For instance, you can disable the GPS locator or Direct Push
                    on your phone. Each Direct Push notification includes an
                    unsubscribe link, which is explained in the "Why does
                    PizzaBox collect your Personal Information?" section.
                  </p>
                  <p>
                    CHILDREN’S PRIVACY
                    <br />
                    PizzaBox Platforms and the Service are designed and
                    available exclusively for individuals aged 13 years or
                    older. PizzaBox does not knowingly Process any Personal
                    Information from children under the age of 13. If you
                    believe Personal Information has been collected from
                    children under the age of 13 or if you are a parent or legal
                    guardian concerned that your child under 13 has provided
                    Personal Information, please contact us as detailed in the
                    "How to contact us?" section.
                  </p>
                  <h4>How do we use cookies?</h4>
                  <p>
                    A cookie is a small text file stored in a dedicated location
                    on your computer, mobile device, tablet, or other devices
                    when using your browser to visit an online service. Other
                    tracking technologies such as web beacons and tracking
                    pixels may be used for similar purposes. In this Policy,
                    these tracking technologies collectively referred to as
                    “Cookies.” Personal Information collected with Cookies by
                    PizzaBox or on its behalf receives the same level of
                    confidentiality as any other Personal Information held by
                    PizzaBox.
                  </p>
                  <h4>Strictly Necessary Cookies</h4>
                  <p>
                    These Cookies are vital for the Service to function and
                    cannot be disabled in our systems. They are necessary for
                    basic Service or PizzaBox Platforms functions, such as
                    signing in, adding items to a cart, or e-billing. They
                    maintain continuity between pages within the same session.
                  </p>
                  <h4>Non-essential Cookies</h4>
                  <p>
                    Subject to your consent, PizzaBox and/or its third-party
                    partners may utilize non-essential Cookies in connection
                    with the Service, the PizzaBox Platforms, and Messages,
                    including the following:
                  </p>
                  <ul>
                    <li>
                      Functional Cookies: These enable enhanced functionality
                      and personalization, remembering preferences and choices
                      such as username, region, and language.
                    </li>
                    <li>
                      Social Media Cookies: With your consent, social media
                      Cookies like the Facebook pixel provide personalized
                      offers, optimize and build audiences for advertising
                      campaigns on social media platforms.
                    </li>
                    <li>
                      Advertising Cookies: Subject to your consent, these gather
                      information from your devices to display relevant
                      advertisements and track interaction. Partners may share
                      data with us, including statistics on ad performance. To
                      manage or disable Advertising Cookies, visit
                      <a href="http://www.youronlinechoices.com/">Click here</a>
                      .
                    </li>
                    <li>
                      Analytic Cookies: Used for statistical purposes, these
                      help understand traffic patterns, measure and improve
                      Service effectiveness, and track Message interactions. All
                      information collected is aggregated and anonymous. To opt
                      out of Google Analytics tracking, visit
                      <a href="http://tools.google.com/dlpage/gaoptout">
                        Click here
                      </a>
                      .
                    </li>
                    <li>
                      Tracking Cookies: With your consent, these gather
                      information about your usage across various websites to
                      offer tailored content during Service use.
                    </li>
                  </ul>
                  <p>
                    You can refuse the use of specific Cookies by following the
                    instructions provided below each type.
                  </p>
                  <h4>How to manage cookies</h4>
                  <p>
                    Except for strictly necessary Cookies, PizzaBox only places
                    Cookies on your devices upon your consent, and these Cookies
                    are retained for a maximum of thirteen (13) months from
                    their placement. Following this period, renewed consent is
                    required.
                  </p>
                  <p>
                    Cookie management or disabling can be performed through your
                    browser’s Cookie settings and mobile device’s advertising
                    settings. Social media Cookies (“plugins”) management can
                    also be executed by referring to the policies of these
                    networks, where applicable:
                  </p>
                  <div class="social-icon">
                    <ul>
                      <li>
                        <a
                          href="https://www.facebook.com/privacy/policies/cookies/?entry_point=cookie_policy_redirect&entry=0"
                          target="_blank"
                        >
                          {/* <i class="fa fa-facebook" aria-hidden="true"></i> */}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="1em"
                            viewBox="0 0 320 512"
                            style={{ fill: "#fff" }}
                          >
                            <path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z" />
                          </svg>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://fr.linkedin.com/legal/cookie-policy"
                          target="_blank"
                        >
                          {/* <i class="fa fa-twitter" aria-hidden="true"></i> */}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="1em"
                            viewBox="0 0 512 512"
                            style={{ fill: "#fff" }}
                          >
                            <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z" />
                          </svg>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://help.twitter.com/en/safety-and-security/x-do-not-track"
                          target="_blank"
                        >
                          {/* <i class="fa fa-linkedin" aria-hidden="true"></i> */}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="1em"
                            viewBox="0 0 448 512"
                            style={{ fill: "#fff" }}
                          >
                            <path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z" />
                          </svg>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.facebook.com/privacy/policies/cookies/?entry_point=cookie_policy_redirect&entry=0"
                          target="_blank"
                        >
                          {/* <i class="fa fa-instagram" aria-hidden="true"></i> */}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="1em"
                            viewBox="0 0 448 512"
                            style={{ fill: "#fff" }}
                          >
                            <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
                          </svg>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <p>
                    However, refusing Cookies may limit your use of Service
                    features, PizzaBox Platforms, and Messages, while the
                    advertisements you receive will be less tailored to your
                    preferences. For more information on managing or disabling
                    non-essential Cookies, visit{" "}
                    <a href="https://www.allaboutcookies.org/manage-cookies/">
                      All About Cookies
                    </a>{" "}
                    and{" "}
                    <a href="https://optout.aboutads.info/?c=2&lang=EN">
                      About Ads Opt-Out.
                    </a>
                  </p>
                  <h4>How do we provide online advertising?</h4>
                  <p>
                    PizzaBox and its third-party partners utilize Cookies,
                    questionnaires, or mobile advertising identifiers to collect
                    Personal Information for providing advertisements about
                    goods and services. This could be on PizzaBox Platforms,
                    Establishment’s platforms, or other websites. This
                    information is gathered through social media or advertising
                    Cookies, or directly from you when participating in surveys
                    or consenting to receive Messages.
                  </p>
                  <p>
                    Your mobile advertising identifier (e.g., IDFA or AAID)
                    might also be used by PizzaBox and advertising partners,
                    subject to your preferences on mobile devices, for targeting
                    and measuring advertising campaign effectiveness. PizzaBox
                    AI ensures advertising is not directed at children under 13.
                  </p>
                  <p>
                    For further details on advertising practices, please contact
                    us as outlined in the "How to contact us?" section.
                  </p>
                  <p>
                    Do Not Track <br />
                    PizzaBox does not respond to Do Not Track (“DNT”) signals
                    transmitted by web browsers, given the absence of a
                    universally accepted standard on interpreting such signals
                    currently.
                  </p>
                  <h4>
                    Where is your Personal Information stored and transferred?
                  </h4>
                  <p>
                    Your Personal Information may be held or shared by PizzaBox with third parties in locations beyond your province,
                    territory, state, or country of residence. PizzaBox ensures
                    the transfer of your Personal Information complies with
                    legal standards, using appropriate technical,
                    organizational, contractual, or lawful measures to safeguard
                    it. For further details on security measures, please contact
                    us as detailed in the "How to contact us?" section.
                  </p>
                  <h4>What are your rights?</h4>
                  <p>
                    Subject to applicable laws, you may have rights including
                    access, rectification, withdrawal of consent,
                    non-discrimination, restriction, objection, or data
                    portability concerning your Personal Information Processed
                    by PizzaBox. To exercise these rights, please contact us as
                    outlined in the "How to contact us?" section.
                  </p>
                  <h4>How to contact us?</h4>
                  <p>
                    For inquiries, comments, complaints, or to exercise your
                    rights under applicable laws, please use the “Contact Us”
                    link on the Service or PizzaBox Platforms where available.
                    Alternatively, contact our Data Protection Officer at:
                  </p>
                  <p>
                    CloudBrands, Inc. 2055 LIMESTONE RD STE 200-C, WILMINGTON,
                    DE 19808
                  </p>
                  <p>Email: hello@restogpt-ai.com</p>
                  <p>
                    Requests will be handled promptly based on applicable laws.
                    PizzaBox takes swift corrective action upon discovering any
                    non-compliance with this Policy. PizzaBox shall not be
                    liable for indirect, incidental, consequential, or punitive
                    damages related to this Policy.
                  </p>
                  <p>
                    PizzaBox is not Processing your Personal Information in a
                    manner that is compliant with this Policy or with the
                    applicable laws.
                  </p>
                  <h4>Changes to this policy</h4>
                  <p>
                    ****PizzaBox frequently revises this Policy to keep it up to
                    date with applicable legislation and its operations. If
                    material changes are made to the Policy, then update notices
                    (such as online notices or emails) may be used to alert you
                    of such changes. Otherwise, the posting of the revised
                    Policy on the Service and PizzaBox Platforms shall be
                    considered sufficient notice to you, and by continuing to
                    use the Service, the PizzaBox Platforms or the Messages or
                    by submitting Personal Information to us, you are consenting
                    to any changes to our Policy.
                  </p>
                  <p>August 1, 2022</p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <div className="footer">
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Index;
