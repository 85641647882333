import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col, Nav, Navbar } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import Typewriter from "typewriter-effect";
import Logo from "../images/logo.svg";
import MobileLogo from "../images/mobileLogo.svg";
import BeforeS from "../images/before.png";
import AfterS from "../images/after.png";
import BeforeW from "../images/before-site.png";
import AfterW from "../images/after-site.png";
import PizzaAfter from "../images/pizza-after.png";
// import MobileBanner from "../images/mobile-banner.png";
import MobileBanner from "../images/mobile-banner1.png";
import navIcon from "../images/nav.png";
import PizzaLogo from "../images/pizzaLogo.svg";
import lineOne from "../images/line-1.png";
import WarningIcon from "../images/warning-icon.png";
import InfoIcon from "../images/info.png";
import lineThree from "../images/line-3.png";
import Search from "../images/search.png";

import FormNew from "../components/Form/FormNew";
import FromTwo from "../components/Form/FromTwo";

import Loader from "../components/Loader/Loader";

import BannerWork from "../components/BannerWork/index";
import Benefits from "../components/Benefits/index";
import SuccessGenerated from "../components/SuccessGenerated/index";
import ModalSection from "../components/ModalSection/index";
import Footer from "../components/Footer/index";
import Demo from "../components/Demo/index";
import Warning from "../popup/Warning/Warning";

import useWindowResize from "../hooks/common/useWindowResize";
import HttpClient from "../utils/httpClient";

const Homepage = () => {
  const words = ["Discovery", "Customer", "Data", "Marketing"];

  const { screenWidth, screenHeight } = useWindowResize();

  const [isScrolled, setIsScrolled] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [showWarning, setShowWarning] = React.useState(false);
  const [showCircles, setShowCircles] = React.useState(false);
  const [currentWordIndex, setCurrentWordIndex] = React.useState(0);

  const [sliderImage, setSliderImage] = React.useState({});
  const [percent, setPercent] = React.useState(50);
  const [isDragging, setIsDragging] = React.useState(false);
  const [comp, setComp] = React.useState("storefront");
  const [compRight, setCompRight] = React.useState(false);
  const [compLoading, setCompLoading] = React.useState(false);

  const topRef = React.useRef(null);
  const pizzariaRef = React.useRef(null);
  const featuresRef = React.useRef(null);
  const caseRef = React.useRef(null);
  const priceRef = React.useRef(null);
  const formRef = React.useRef(null);

  React.useEffect(() => {
    isLoading();
    fetchImage();
    // setLoading(true);
    // getUserIP();
    // allData();
    // let hash = window.location.hash;
    // setHashData(hash)
  }, []);

  React.useLayoutEffect(() => {
    const hash = window.location.hash;
    console.log("hash", hash);
    if (hash) {
      if (hash === "#PIZZERIAS" && pizzariaRef.current) {
        // Scroll again if there was any chance that the layout has changed
        pizzariaRef.current.scrollIntoView({ behavior: "smooth" });
      }
      if (hash === "#WHYPIZZABOX" && featuresRef.current) {
        // Scroll again if there was any chance that the layout has changed
        featuresRef.current.scrollIntoView({ behavior: "smooth" });
      }
      if (hash === "#WHATSINTHEBOX" && caseRef.current) {
        // Scroll again if there was any chance that the layout has changed
        caseRef.current.scrollIntoView({ behavior: "smooth" });
      }
      if (hash === "#PRICING" && priceRef.current) {
        // Scroll again if there was any chance that the layout has changed
        priceRef.current.scrollIntoView({ behavior: "smooth" });
      }
      if (hash === "#PARTNERWITHUS" && formRef.current) {
        // Scroll again if there was any chance that the layout has changed
        formRef.current.scrollIntoView({ behavior: "smooth" });
        handleFocus();
      }
    } else {
      if (topRef.current) {
        topRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);

  React.useEffect(() => {
    const hash = window.location.hash;
    if (!hash && topRef.current) {
      topRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  React.useEffect(() => {
    const handleScroll = () => {
      if (screenWidth > 992) {
        if (window.scrollY > 50) {
          setIsScrolled(true);
        } else {
          setIsScrolled(false);
        }
      } else {
        if (window.scrollY > 10) {
          setIsScrolled(true);
        } else {
          setIsScrolled(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [screenWidth]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 2000); // Change every 2 seconds

    return () => clearInterval(interval);
  }, []);

  const isLoading = (loading) => {
    // setLoading(loading);
    if (loading) {
      setLoading(loading);
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
    setTimeout(() => {
      checkWebsiteStatus();
    }, 2000);
  };

  const checkWebsiteStatus = async () => {
    let result = await HttpClient.requestData("restogptstatus", "GET");
    if (result.status === false) {
      handleShowWarning();
    }
  };

  const fetchImage = async () => {
    setCompLoading(true);
    let result = await HttpClient.requestData("pizzaboximg", "GET");
    if (result) {
      setSliderImage(result);
      setCompLoading(false);
    }
  };

  const handleShowWarning = () => setShowWarning(true);

  const handleCloseWarning = () => {
    setShowWarning(false);
  };

  const handleFocus = () => {
    console.log("handleFocus called");
    document.getElementById("restaurantMenu").focus();
    setShowCircles(true);
    // Blink the circles
    setTimeout(() => {
      setShowCircles(false);
      setTimeout(() => setShowCircles(true), 500);
    }, 500);
  };

  const handleScroll = (ref, id) => {
    let navbarHeightOffset = document.querySelector(".navbar").offsetHeight;
    let navbarHeight = navbarHeightOffset + 500;
    let element = document.getElementById(id);
    let scrollTop = element - navbarHeight;
    if (element) {
      window.scrollTo({
        top: scrollTop,
        behavior: "smooth",
      });
    }
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    const containerRect = e.currentTarget.getBoundingClientRect();
    const offsetX = e.clientX - containerRect.left;
    const newPercent = (offsetX / containerRect.width) * 100;
    setPercent(Math.max(0, Math.min(100, newPercent))); // Keep percent between 0 and 100
  };

  const handleTouchMove = (e) => {
    if (!isDragging) return;
    const containerRect = e.currentTarget.getBoundingClientRect();
    const offsetX = e.touches[0].clientX - containerRect.left;
    const newPercent = (offsetX / containerRect.width) * 100;
    setPercent(Math.max(0, Math.min(100, newPercent))); // Keep percent between 0 and 100
  };

  const handleMouseDown = () => {
    setIsDragging(true);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleTouchStart = () => {
    setIsDragging(true);
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
  };

  const handleChangeComp = (compName) => {
    setComp(compName);
  };

  const handleOpenPizzaSubscription = () => {
    window.open("http://subscription.pizzabox.ai", "_blank")
  }

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="wrapper">
            <div className="top-container">
              <div id="TOP" ref={topRef}></div>
              <div className={`header ${isScrolled ? "headerSolid" : ""}`}>
                <Navbar collapseOnSelect expand="lg">
                  <Container
                    fluid
                    // className="justify-content-end justify-content-md-between"
                    className="justify-content-between menu-bar"
                  >
                    {screenWidth > 992 ? (
                      <Navbar.Brand href="/" className="logo">
                        <img src={MobileLogo} />
                      </Navbar.Brand>
                    ) : (
                      <Navbar.Brand href="/" className="mobile-logo-desk">
                        <img src={MobileLogo} />
                      </Navbar.Brand>
                    )}
                    <Navbar.Toggle aria-controls="basic-navbar-nav">
                      <img src={navIcon} />
                    </Navbar.Toggle>
                    <Navbar.Collapse
                      id="basic-navbar-nav"
                      className="justify-content-end center"
                    >
                      <Nav className="menu">
                        {/* <Nav.Link href="#WORKS">HOW IT WORKS</Nav.Link> */}
                        {/* <span>|</span> */}
                        <Nav.Link
                          href="#WHYPIZZABOX"
                          className="nav-link dot"
                          onClick={() =>
                            handleScroll(featuresRef, "WHYPIZZABOX")
                          }
                        >
                          WHY PIZZABOX
                        </Nav.Link>
                        <Nav.Link
                          href="#PIZZERIAS"
                          className="nav-link dot"
                          onClick={() => handleScroll(pizzariaRef, "PIZZERIAS")}
                        >
                          PIZZERIAS
                        </Nav.Link>
                        <Nav.Link
                          href="#WHATSINTHEBOX"
                          className="nav-link dot"
                          onClick={() =>
                            handleScroll(featuresRef, "WHATSINTHEBOX")
                          }
                        >
                          WHAT'S IN THE BOX
                        </Nav.Link>
                        <Nav.Link
                          href="#PRICING"
                          className="nav-link dot"
                          onClick={() => handleScroll(caseRef, "PRICING")}
                        >
                          PRICING
                        </Nav.Link>
                        <Nav.Link
                          href=""
                          className="nav-link dot"
                          onClick={handleOpenPizzaSubscription}
                        >
                          PIZZA SUBSCRIPTIONS
                        </Nav.Link>
                        <Nav.Link
                          href="#PARTNERWITHUS"
                          className="gen-btn"
                          onClick={handleFocus}
                        >
                          PARTNER WITH US
                        </Nav.Link>
                      </Nav>
                    </Navbar.Collapse>
                  </Container>
                </Navbar>
              </div>
              <div className="header-section"></div>
              <div className="banner">
                <Container>
                  <Row>
                    <Col xl="6" xxl="6" lg="12" md="12" id="Start">
                      <div id="form-scroll"></div>
                      <div className="banner-bottom-container">
                        {screenWidth > 992 ? (
                          <p className="banner-bottom-text">
                            <span className="shell-name">Sell</span>{" "}
                            <span className="line">
                              More <img src={lineThree} />
                            </span>{" "}
                            Pies <br />
                            and Keep <br />
                            What's Yours
                          </p>
                        ) : (
                          <p className="banner-bottom-text">
                            <span className="shell-name">Sell</span>{" "}
                            <span className="line">
                              More <img src={lineThree} />
                            </span>{" "}
                            Pies and Keep What's Yours
                          </p>
                        )}
                      </div>
                      <button
                        className="banner-text-container"
                        onClick={handleFocus}
                      >
                        Join PizzaBox AI <br />{" "}
                        <span>Only for Local Pizzerias</span>
                      </button>
                    </Col>
                    <Col
                      xxl="6"
                      md={{ span: 12, order: "last" }}
                      xl={{ span: 6, order: "last" }}
                      lg={{ span: 12, order: "last" }}
                    // sm={{ span: 6, order: 1 }}
                    // xs={{ span: 6, order: 1 }}
                    // className="d-flex"
                    >
                      <div className="banner-work-left">
                        {/* {screenWidth > 992 ? (
                          <div className="banner-buttom-text">
                            <p>
                              See the Difference{" "}
                              <img src={PizzaLogo} alt="PizzaLogo" /> Makes
                            </p>
                          </div>
                        ) : null} */}
                        <div className="banner-buttom-text">
                          <p>
                            See the Difference{" "}
                            <img src={PizzaLogo} alt="PizzaLogo" /> Makes
                          </p>
                        </div>
                        <div className="banner-btn-group">
                          <button
                            className={`banner-work-left-btn ${comp === "storefront" ? "active" : ""
                              }`}
                            onClick={() => handleChangeComp("storefront")}
                          >
                            Storefront
                          </button>
                          <button
                            className={`banner-work-left-btn ${comp === "website" ? "active" : ""
                              }`}
                            onClick={() => handleChangeComp("website")}
                          >
                            Website
                          </button>
                        </div>
                        <div className="banner-after-container">
                          {comp === "storefront" ? (
                            <div
                              className="container"
                              onMouseMove={handleMouseMove}
                              onTouchMove={handleTouchMove}
                              onMouseUp={handleMouseUp}
                              onTouchEnd={handleTouchEnd}
                            >
                              {compLoading ? (
                                <div className="banner-loader">
                                  <div className="spinner"></div>
                                </div>
                              ) : (
                                <>
                                  <div className="image-wrapper">
                                    <img
                                      src={sliderImage.after1}
                                      className="image before"
                                      alt="Before"
                                    />
                                    <img
                                      src={sliderImage.before1}
                                      className="image after"
                                      alt="After"
                                      style={{
                                        clipPath: `inset(0 ${100 - percent
                                          }% 0 0)`,
                                      }}
                                    />
                                    <div
                                      className="image-divider"
                                      style={{ left: `${percent}%` }}
                                      onMouseDown={handleMouseDown}
                                      onTouchStart={handleTouchStart}
                                      tabIndex={-1}
                                    ></div>
                                  </div>
                                </>
                              )}
                            </div>
                          ) : comp === "website" ? (
                            <div
                              className="container"
                              onMouseMove={handleMouseMove}
                              onTouchMove={handleTouchMove}
                              onMouseUp={handleMouseUp}
                              onTouchEnd={handleTouchEnd}
                            >
                              {compLoading ? (
                                <div className="banner-loader">
                                  <div className="spinner"></div>
                                </div>
                              ) : (
                                <>
                                  <div className="image-wrapper">
                                    <img
                                      src={sliderImage.after2}
                                      className="image before"
                                      alt="Before"
                                    />
                                    <img
                                      src={sliderImage.before2}
                                      className="image after"
                                      alt="After"
                                      style={{
                                        clipPath: `inset(0 ${100 - percent
                                          }% 0 0)`,
                                      }}
                                    />
                                    <div
                                      className="image-divider"
                                      style={{ left: `${percent}%` }}
                                      onMouseDown={handleMouseDown}
                                      onTouchStart={handleTouchStart}
                                      tabIndex={-1}
                                    ></div>
                                  </div>
                                </>
                              )}
                            </div>
                          ) : null}
                        </div>
                        {/* {screenWidth < 992 ? (
                          <div className="banner-buttom-text">
                            <p>
                              See the Difference{" "}
                              <img src={PizzaLogo} alt="PizzaLogo" /> Makes
                            </p>
                          </div>
                        ) : null} */}
                      </div>
                    </Col>
                  </Row>
                  <Demo />
                </Container>
              </div>
              <div
                id="WHYPIZZABOX"
                ref={featuresRef}
                className="banner-scroll"
              ></div>
            </div>

            <div className="orange-background">
              <div className="banner-work">
                <BannerWork isLoading={isLoading} showCircles={showCircles} />
              </div>
              <div
                id="PIZZERIAS"
                ref={pizzariaRef}
                className="features-scroll"
              ></div>
              <div className="success-generated">
                <SuccessGenerated />
                <div
                  id="WHATSINTHEBOX"
                  ref={priceRef}
                  className="prize-scroll"
                ></div>
              </div>
            </div>
            <div className="case-study">
              <div className="benefits">
                <Benefits />
                <div
                  id="PRICING"
                  ref={caseRef}
                  className="case-study-scroll"
                ></div>
              </div>
              <div className="modal-section">
                <ModalSection handleFocus={handleFocus} />
              </div>
            </div>
            <div className="footer">
              <Footer />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Homepage;
