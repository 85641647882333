import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import HomePage from "./pages/Homepage";
import PrivacyPolicy from './privacyPolicy/index';
import TermAndCondition from './termAndCondition/index';
import OneForm from "./onbording/OneForm";
import Checkout from "./onbording/components/stripe/index";
import Buffer from "./onbording/components/thankyou/buffer";
import ThankYou from "./onbording/components/thankyou/index";
import Blog from './blog/index';
import BlogDetail from './blog/blogDetail/index';
import News from "./news/index";
import NewsDetail from './news/newsDetail/index';
import NotFound from "./404/NotFound";
import './App.css';

function App() {
  return (
    <>
     <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/term-and-condition" element={<TermAndCondition />} />
          <Route path="/welcome" element={<OneForm siteName="welcome" />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/buffer" element={<Buffer />} />
          <Route path="/thank-you" element={<ThankYou />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog-detail/:id/:params" element={<BlogDetail />} />
          <Route path="/news" element={<News />} />
          <Route path="/news-detail/:id" element={<NewsDetail />} />
          <Route path="/en" element={<Navigate to="/" />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
