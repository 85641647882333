import React from "react";
import { Container, Row, Col, Nav, Navbar } from "react-bootstrap";
import Logo from "../images/logo.svg";
// import BlogBannerImg from "../images/blogBanner.png";
import BlogBannerImg from "../images/pizzaBox.jpg";
import navIcon from "../images/nav.png";

import useWindowResize from "../hooks/common/useWindowResize";

const BlogHead = () => {
  const { screenWidth, screenHeight } = useWindowResize();

  const handleOpenPizzaSubscription = () => {
    window.open("http://subscription.pizzabox.ai", "_blank")
  }

  return (
    <>
      <div className="header">
        <Navbar collapseOnSelect expand="lg">
          <Container fluid className="justify-content-between menu-bar">
            {screenWidth > 992 ? (
              <Navbar.Brand href="/" className="logo">
                <img src={Logo} />
              </Navbar.Brand>
            ) : (
              <Navbar.Brand href="/" className="mobile-logo-desk">
                <img src={Logo} />
              </Navbar.Brand>
            )}
            <Navbar.Toggle aria-controls="basic-navbar-nav">
              <img src={navIcon} />
            </Navbar.Toggle>
            <Navbar.Collapse
              id="basic-navbar-nav"
              className="justify-content-center center"
            >
              <Nav className="menu">
                <Nav.Link
                  href="https://pizzabox.ai/#WHYPIZZABOX"
                  className="nav-link dot"
                >
                  WHY PIZZABOX
                </Nav.Link>
                <Nav.Link
                  href="https://pizzabox.ai/#PIZZERIAS"
                  className="nav-link dot"
                >
                  PIZZERIAS
                </Nav.Link>
                <Nav.Link
                  href="https://pizzabox.ai/#WHATSINTHEBOX"
                  className="nav-link dot"
                >
                  WHAT'S IN THE BOX
                </Nav.Link>
                <Nav.Link
                  href="https://pizzabox.ai/#PRICING"
                  className="nav-link dot"
                >
                  PRICING
                </Nav.Link>
                <Nav.Link
                  href=""
                  className="nav-link dot"
                  onClick={handleOpenPizzaSubscription}
                >
                  PIZZA SUBSCRIPTIONS
                </Nav.Link>
                <Nav.Link
                  href="https://pizzabox.ai/#PARTNERWITHUS"
                  className="gen-btn"
                >
                  PARTNER WITH US
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
      <div className="blog-banner">
        <img
          src={BlogBannerImg}
          alt="blog-banner"
          className="blog-banner-img"
        />
      </div>
      <div className="banner-footer">
        <h3>The PizzaBox Pie Guide</h3>
      </div>
    </>
  );
};

export default BlogHead;
