import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import PizzaLogo from "../../images/pizzaLogo.svg";
import PhoneBack from "../../images/phone-back.png";
import P1 from "../../images/p1.png";
import P2 from "../../images/p2.png";
import P3 from "../../images/p3.png";
import P4 from "../../images/p4.png";
import Tick from "../../images/Tick-white.png";

import StorfrontModal from "../../popup/Storefront/StorefrontModal";
import ManagementModal from "../../popup/Management/ManagementModal";
import CrmModal from "../../popup/CRM/CrmModal";
import LastMileModal from "../../popup/LastMile/LastMileModal";

import useWindowResize from "../../hooks/common/useWindowResize";

const Index = () => {
  const { screenWidth, screenHeight } = useWindowResize();
  const options = {
    loop: false,
    items: 1,
    margin: 0,
    mouseDrag: false,
    autoplay: false,
    dots: false,
    autoplayTimeout: 8500,
    smartSpeed: 450,
    nav: true,
    navText: ["<", ">"],
    responsive: {
      0: {
        items: 1,
        dots: true,
        nav: false,
      },

      992: {
        items: 1,
      },
    },
  };
  return (
    <>
      <Container>
        <Row>
          <Col lg="12">
            <div className="benefits-header">
              <h1>
                What’s in the <img src={PizzaLogo} />
              </h1>
              <p>
                <span>End-to-end Technology</span> Made for Local Pizzerias
              </p>
              <div className="benefits-work-subpart">
                <div className="benefits-head-block">
                  <img src={Tick} alt="Tick" />
                  Acquire <span>[Customers]</span>
                </div>
                <div className="benefits-head-block">
                  <img src={Tick} alt="Tick" />
                  Manage <span>[Orders]</span>
                </div>
                <div className="benefits-head-block">
                  <img src={Tick} alt="Tick" />
                  Deliver <span>[Without Drivers]</span>
                </div>
                <div className="benefits-head-block">
                  <img src={Tick} alt="Tick" />
                  Scale <span>[With Automation]</span>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <div className="sub-features">
          <Container className="for-mobile">
            <Swiper
              slidesPerView={1}
              spaceBetween={30}
              loop={true}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination]}
              className="pizza_swiper"
            >
              <SwiperSlide>
                <div className="item">
                  <h3>
                    DATA MARKETING <br /> AUTOMATION
                    <p>[DATA-DRIVEN CUSTOMER ENGAGEMENT CAMPAIGNS]</p>
                  </h3>
                  <div className="image">
                    <img src={PhoneBack} className="back" />
                    <img src={P2} className="phone" />
                  </div>
                  <CrmModal />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="item">
                  <h3>
                    WEBSITE, STOREFRONT <br />{" "}
                    <span className="text-small">AND</span> DISCOVERY
                    <p>[CATERING + ORDERING SOLUTIONS]</p>
                  </h3>
                  <div className="image">
                    <img src={PhoneBack} className="back" />
                    <img src={P1} className="phone" />
                  </div>
                  <StorfrontModal />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="item">
                  <h3>
                    AI ORDER <br />
                    MANAGEMENT
                    <p>[ADVANCED MENU MANAGEMENT SOLUTIONS]</p>
                  </h3>
                  <div className="image">
                    <img src={PhoneBack} className="back" />
                    <img src={P3} className="phone" />
                  </div>
                  <ManagementModal />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="item">
                  <h3>
                    LAST MILE <br />
                    DELIVERY MANAGEMENT
                    <p>[ADVANCED DELIVERY SOLUTIONS]</p>
                  </h3>
                  <div className="image">
                    <img src={PhoneBack} className="back" />
                    <img src={P4} className="phone" />
                  </div>
                  <LastMileModal />
                </div>
              </SwiperSlide>
            </Swiper>
          </Container>
          <Container className="for-desktop">
            <Row>
              <Col lg="6">
                <div className="item">
                  <h3>
                    DATA MARKETING <br /> AUTOMATION
                    <p>[DATA-DRIVEN CUSTOMER ENGAGEMENT CAMPAIGNS]</p>
                  </h3>
                  <div className="image">
                    <img src={PhoneBack} className="back" />
                    <img src={P2} className="phone" />
                  </div>
                  <CrmModal />
                </div>
              </Col>
              <Col lg="6">
                <div className="item">
                  <h3>
                    WEBSITE, STOREFRONT <br />{" "}
                    <span className="text-small">AND</span> DISCOVERY
                    <p>[CATERING + ORDERING SOLUTIONS]</p>
                  </h3>
                  <div className="image">
                    <img src={PhoneBack} className="back" />
                    <img src={P1} className="phone" />
                  </div>
                  <StorfrontModal />
                </div>
              </Col>
              <Col lg="6">
                <div className="item">
                  <h3>
                    AI ORDER <br />
                    MANAGEMENT
                    <p>[ADVANCED MENU MANAGEMENT SOLUTIONS]</p>
                  </h3>
                  <div className="image">
                    <img src={PhoneBack} className="back" />
                    <img src={P3} className="phone" />
                  </div>
                  <ManagementModal />
                </div>
                <div id="BENEFITS" className="beni-scroll"></div>
              </Col>
              <Col lg="6">
                <div className="item">
                  <h3>
                    LAST MILE <br />
                    DELIVERY MANAGEMENT
                    <p>[ADVANCED DELIVERY SOLUTIONS]</p>
                  </h3>
                  <div className="image">
                    <img src={PhoneBack} className="back" />
                    <img src={P4} className="phone" />
                  </div>
                  <LastMileModal />
                </div>
                <div id="BENEFITS" className="beni-scroll"></div>
              </Col>
            </Row>
          </Container>
        </div>
      </Container>
    </>
  );
};

export default Index;
