import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import PizzaLogoClub from "../../images/pizzaClub.svg";
import PizzaLogo from "../../images/pizzaLogo.svg";
import Channel from "../../images/channel.png";
import ChannelBox from "../../images/channel_box.png";

import BeforeS from "../../images/before.png";
import AfterS from "../../images/after.png";
import BeforeW from "../../images/before-site.png";
import AfterW from "../../images/after-site.png";

import B1 from "../../images/banner-icon/b1.png";
import B2 from "../../images/banner-icon/b2.png";
import B3 from "../../images/banner-icon/b3.png";
import B4 from "../../images/banner-icon/b4.png";
import B5 from "../../images/banner-icon/b5.png";
import B6 from "../../images/banner-icon/b6.png";
import B7 from "../../images/banner-icon/b7.png";
import B8 from "../../images/banner-icon/b8.png";
import B9 from "../../images/banner-icon/b9.png";
import B10 from "../../images/banner-icon/b10.png";
import Hand from "../../images/hand.png";
import PizzaAfter from "../../images/pizza-after.png";
import Search from "../../images/search.png";

import Doordash from "../../images/LI/Doordash.png";
import UberEats from "../../images/LI/UberEats.png";
import Grubhub from "../../images/LI/Grubhub.png";
import Yelp from "../../images/LI/yelp.png";
import Google from "../../images/LI/google.png";
import Apple from "../../images/LI/apple.png";
import Instagram from "../../images/LI/instagram.png";
import Facebook from "../../images/LI/facebook.png";

import FromTwo from "../Form/FromTwo";
import Warning from "../../popup/Warning/Warning";

import useWindowResize from "../../hooks/common/useWindowResize";

const Index = ({ isLoading, showCircles }) => {
  const { screenWidth, screenHeight } = useWindowResize();

  const [percent, setPercent] = React.useState(50);
  const [comp, setComp] = React.useState("storefront");
  const [compRight, setCompRight] = React.useState(false);

  const handleMouseMove = (event) => {
    const container = event.currentTarget;
    const containerRect = container.getBoundingClientRect();
    const offsetX = event.clientX - containerRect.left;
    const width = containerRect.width;
    const newPercent = (offsetX / width) * 100;
    setPercent(newPercent);
  };

  const handleChangeComp = (compName) => {
    setComp(compName);
  };

  const handleShowPopup = () => {
    setCompRight(!compRight);
  };

  const handleToFlintridge = () => {
    window.open("http://flintridgepizzakitchen.com", "_blank");
  };

  return (
    <>
      {" "}
      <Container>
        <Row>
          <Col lg="12">
            <div className="banner-work-header">
              <h1>
                <span>PizzaBox Enables Your 🍕 Customers to</span> Order
                Directly from You <span>[and not the Third-party]</span>
              </h1>
            </div>
          </Col>
        </Row>
        <div className="banner-work-block">
          <Row>
            <Col lg="6">
              <div className="text" id="input-id">
                <p className="input-text-para">
                  <span>Start here... Submit</span> your menu and claim your
                  🍕pizza<span>shop</span> [it’s free]
                  <Warning />
                </p>
                <div style={{ flexGrow: 1 }}>
                  <FromTwo isLoading={isLoading} showCircles={showCircles} />
                  {/* <FromTwo /> */}
                </div>
                <div className="banner-work-btn-container-left">
                  <button
                    className="banner-work-btn-left"
                    onClick={handleToFlintridge}
                  >
                    View Your [future]<span>Pizza</span>shop
                    <img src={Search} alt="Search" />
                  </button>
                </div>
                {/* <div className="fake-container">
                  <div>
                    <p>Fake data is detected by AI</p>
                  </div>
                  <div>
                    <Warning />
                  </div>
                </div> */}
              </div>
            </Col>
            <Col lg="6">
              <div className="banner-work-right">
                <div className="banner-work-buttom-text">
                  <p>
                    Made for Local Pizza <span>Heroes!</span>
                  </p>
                </div>
                <div
                  className={
                    compRight ? "banner-work-popup" : "banner-work-popup-hide"
                  }
                >
                  <div className="popup-content">
                    <p className="popup-upper-text">
                      With <span>PizzaBox</span> - it's always <span>your</span>{" "}
                      brand, <span>your</span> customers and <span>your</span>{" "}
                      business. We provide the tools so you can keep MORE of
                      what's <span>YOURS!</span>
                    </p>
                    <ul>
                      <li>
                        🔥<span>Raise capital</span> and pre-sell pizzas with
                        Pizza Subscription Plan [exclusive to PizzaBox]
                      </li>
                      <li>
                        🔥<span>Get back $0.99 Per Order</span> to cover your
                        pizza box costs
                      </li>
                      <li>
                        🔥<span>Branded Website</span> and{" "}
                        <span>Online Ordering Storefront</span> <br />
                        [with features your customers love] - managed by your AI
                        employee [pAI-zan]
                      </li>
                      <li>
                        <p>
                          🔥 <span>Be Visible</span> on every online channel
                        </p>{" "}
                        <img src={Doordash} alt="Doordash" />
                        <img src={UberEats} alt="UberEats" />
                        <img src={Grubhub} alt="Grubhub" />
                        <img src={Yelp} alt="Yelp" />
                        <img src={Google} alt="Google" />
                        <img src={Apple} alt="Apple" />
                        <img src={Instagram} alt="Instagram" />
                        <img src={Facebook} alt="Facebook" />
                      </li>
                      <li>
                        🔥<span>Customer Data</span> is YOURS to keep [don't
                        give away your most valuable asset]
                      </li>
                      <li>
                        🔥<span>No More Commission</span> - save [30%] on the
                        imposed killer fees [keep the whole pie]
                      </li>
                      <li>
                        🔥<span>Engage With Your Customers</span> using
                        automated marketing [and loyalty] with targeted messages
                        and personalized offers [email and SMS]
                      </li>
                      <li>
                        🔥<span>Hybrid Delivery</span> - use your own and
                        on-demand drivers
                      </li>
                    </ul>
                    <p className="popup-buttom-text">... and much more</p>
                  </div>
                </div>
                <div className="banner-work-list">
                  <Row>
                    <Col lg="6" sm="12">
                      <div className="item">
                        <img src={B9} />
                        <p>Subscriptions</p>
                      </div>
                      <div className="item">
                        <img src={B10} />
                        <p>Phone AI</p>
                      </div>
                      <div className="item">
                        <img src={B1} />
                        <p>First Party</p>
                      </div>
                      <div className="item">
                        <img src={B3} />
                        <p>Loyalty</p>
                      </div>
                      <div className="item">
                        <img src={B5} />
                        <p>Catering</p>
                      </div>
                    </Col>
                    <Col lg="6" sm="12">
                      <div className="item">
                        <img src={B7} />
                        <p>Refunds</p>
                      </div>
                      <div className="item">
                        <img src={B2} />
                        <p>Multichannel</p>
                      </div>
                      <div className="item">
                        <img src={B4} />
                        <p>Marketing</p>
                      </div>
                      <div className="item">
                        <img src={B6} />
                        <p>Delivery</p>
                      </div>
                      <div className="item">
                        <img src={B8} />
                        <p>AI Automation</p>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="banner-work-btn-container">
                  <button className="banner-work-btn" onClick={handleShowPopup}>
                    View Here Why Pizzerias Love Us
                    <img src={Hand} alt="hand" />
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

export default Index;
