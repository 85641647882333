import React, { useState } from "react";
import { Modal, Row, Col, Button } from "react-bootstrap";
import CloseIcon from "../../images/close-icon.png";
import WarningOrange from "../../images/warning-orange.png";
import WarningYellow from "../../images/warning-yellow.png";
import InfoIcon from "../../images/info.png";

import PizzaLogo from "../../images/pizzaLogo.svg";

function StorefrontModal() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <img src={InfoIcon} className="info-img" alt="info-icon" onClick={handleShow} />

      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        centered
        className="AI-modal-warning"
      >
        <Modal.Body>
          <Button className="close-btn" onClick={handleClose}>
            <img src={CloseIcon} />
          </Button>
          <Row>
            <div className="header-container">
              <div>
                <img src={WarningOrange} alt="WarningOrange" />
              </div>
              <div>
                {" "}
                <h5>
                  Fake requests <span>will not be</span> generated.
                </h5>
              </div>
            </div>
            <div className="modal-list-container">
              <p>AI detects fakes when:</p>
              <ul>
                <li>Restaurant name and the online menu link don't match</li>
                <li>Website and online menu link don't match</li>
                <li>Restaurant name and address [location] don't match</li>
                <li>
                  Restaurant website and concept chosen [pizza] don’t match
                </li>
                <li>
                  If your contacts [name, email, phone number] do not exist or
                  appear fake.
                </li>
              </ul>
            </div>
            <div className="footer-modal-container">
              <div>
                <img src={WarningYellow} alt="WarningYellow" />
              </div>
              <div>
                <h5>
                  Make sure you add your <span>real name</span>, <span>email</span> and <span>phone number</span> since
                  you will only receive the generated storefront there.
                </h5>
              </div>
            </div>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default StorefrontModal;
