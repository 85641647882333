import React from "react";
import RestoCarousel from "../common/RestoCarousel";
// import Slider from "../../images/slider.png";
import PizzaTop from "../../../images/pizzaTop.png"

const Index = () => {
  return (
    <>
      <div className="left-container">
      <img src={PizzaTop} alt="PizzaTop" />
        <div className="left-content">
          {/* <h3 className="onbheader">Why <span>PizzaBox?</span></h3>
          <p className="sub-header">YOUR RESTAURANT'S UNFAIR ADVANTAGE</p>
          <RestoCarousel /> */}
          <h3 className="onbheader">So, What’s Next?</h3>
          <div className="left-container-stepper-modal">
            <div class="left-container-step-modal">
              <div className="left-container-step-left">
                <span class="left-container-step-number-modal">1</span>
                <div class="left-container-step-content-modal">Fill out the form</div>
              </div>
            </div>
            <div class="left-container-divider"></div>
            <div class="left-container-step-modal">
              <div className="left-container-step-left">
                <span class="left-container-step-number-modal">2</span>
                <div class="left-container-step-content-modal">Pay the $1 setup fee</div>
              </div>
            </div>
            <div class="left-container-divider"></div>
            <div class="left-container-step-modal">
              <div className="left-container-step-left">
                <span class="left-container-step-number-modal">3</span>
                <div class="left-container-step-content-modal">
                  Start 30-day, no commitment trial
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
