import React, { useState, useRef, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Typewriter from "typewriter-effect";

import useWindowResize from "../../hooks/common/useWindowResize";
import HttpClient from "../../utils/httpClient";

import PizzaLogo from "../../images/pizza-colon.svg";
import PizzaDemoLogo from "../../images/demo-logo.png";
import TickOrange from "../../images/Tick-orange.svg";
import "./demo.css";

const Index = () => {
  const { screenWidth, screenHeight } = useWindowResize();
  const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    message: "",
  });
  const [errors, setErrors] = useState({});
  const messageContainerRef = useRef(null);
  const [callApi, setCallApi] = useState(false);
  const [success, setSuccess] = useState(false);

  const words = ["Discovery", "Customer", "Data", "Marketing"];

  const handleClickOutside = (event) => {
    if (
      messageContainerRef.current &&
      !messageContainerRef.current.contains(event.target)
    ) {
      setIsOpen(false);
      setSuccess(false);
      setCallApi(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  const toggleMessageContainer = () => {
    setIsOpen(!isOpen);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handlePhoneKeyPress = (e) => {
    const charCode = e.charCode;
    if (charCode < 48 || charCode > 57) {
      e.preventDefault();
    }
  };

  const isValidEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handleFormSubmit = async () => {
    const newErrors = {};
    if (!formData.firstName) newErrors.firstName = "First name is required";
    if (!formData.lastName) newErrors.lastName = "Last name is required";
    if (!formData.phone) {
      newErrors.phone = "Phone is required";
    } else if (formData.phone.length !== 10) {
      newErrors.phone = "Phone number must be 10 digits";
    }
    if (!formData.email) {
      newErrors.email = "Email is required";
    } else if (!isValidEmail(formData.email)) {
      newErrors.email = "Email is not valid";
    }
    if (!formData.message) newErrors.message = "Message is required";

    setErrors(newErrors);
    let data = {
      first_name: formData.firstName,
      last_name: formData.lastName,
      email: formData.email,
      phone: `+1${formData.phone}`,
      message: formData.message,
    };

    console.log("data", data);

    if (Object.keys(newErrors).length === 0) {
      // All fields are valid, proceed with the form submission
      setCallApi(true);
      let result = await HttpClient.requestData(
        "book_demo_widget",
        "POST",
        data
      );
      if (result.status === "done") {
        setCallApi(false);
        setSuccess(true);
        setFormData({
          firstName: "",
          lastName: "",
          phone: "",
          email: "",
          message: "",
        });
      }
    }
  };

  return (
    <Container>
      <Row>
        <Col lg="12">
          <div className="demo-wrapper">
            <div className="demo-content">
              {screenWidth > 1198 ? (
                <div className="demo-input-container">
                  <div className="demo-logo">
                    <img src={PizzaLogo} alt="PizzaLogo" />
                    {/* <p>Message</p> */}
                    <p>
                      <Typewriter
                        options={{
                          strings: [
                            "Pizza Subscriptions. Get customers coming back for more…weekly",
                            "Add Catering Options. Enable large corporations to order",
                            "Build Customer Database with real-time data for re-marketing",
                            "Advanced Pizza Menu Mapping for higher AOV",
                            "Get Customer Feedback. Improve ratings and Yelp reviews"
                          ],
                          autoStart: true,
                          pauseFor: 3000, // Pause for 3 seconds before typing the next sentence
                          loop: true,
                          delay: 30, // Typing speed: 30ms per character
                          deleteSpeed: 10, // Speed to delete text
                          skipAddStyles: true,
                        }}
                      />
                    </p>
                  </div>
                  <div className="demo-btn-container">
                    <button
                      className="demo-btn"
                      onClick={toggleMessageContainer}
                    >
                      BOOK A DEMO
                    </button>
                  </div>
                </div>
              ) : (
                <div className="demo-input-container-mobile">
                  <div className="demo-logo-mobile">
                    
                    <p>
                    <img src={PizzaLogo} alt="PizzaLogo" />
                      <Typewriter
                        options={{
                          strings: [
                            "Pizza Subscriptions. Get customers coming back for more…weekly",
                            "Add Catering Options. Enable large corporations to order",
                            "Build Customer Database with real-time data for re-marketing",
                            "Advanced Pizza Menu Mapping for higher AOV",
                            "Get Customer Feedback. Improve ratings and Yelp reviews"
                          ],
                          autoStart: true,
                          pauseFor: 3000, // Pause for 3 seconds before typing the next sentence
                          loop: true,
                          delay: 30, // Typing speed: 30ms per character
                          deleteSpeed: 10, // Speed to delete text
                          skipAddStyles: true,
                        }}
                      />
                    </p>
                  </div>
                  <div className="demo-btn-container-mobile">
                    <button
                      className="demo-btn"
                      onClick={toggleMessageContainer}
                    >
                      BOOK A DEMO
                    </button>
                  </div>
                </div>
              )}

              <div
                className={`demo-message-container ${isOpen ? "open" : ""}`}
                ref={messageContainerRef}
              >
                {callApi && (
                  <div className="loading-overlay">
                    <div className="spinner"></div>
                  </div>
                )}
                <div className="demo-message-logo">
                  <img src={PizzaDemoLogo} alt="PizzaDemoLogo" />
                </div>
                {success && (
                  <div className="success-container">
                    <div className="success-checkmark">
                      <img src={TickOrange} alt="Success" />
                    </div>
                  </div>
                )}
                <Container
                  style={{ visibility: success ? "hidden" : "visible" }}
                >
                  <Row className="input-container">
                    <Col lg="6" xl="6" xxl="6" md="6" sm="6" className="column">
                      <input
                        type="text"
                        name="firstName"
                        placeholder="First name"
                        className={`message-input ${
                          errors.firstName ? "error" : ""
                        }`}
                        value={formData.firstName}
                        onChange={handleInputChange}
                      />
                      {/* {errors.firstName && (
                        <span className="error-text">{errors.firstName}</span>
                      )} */}
                    </Col>
                    <Col lg="6" xl="6" xxl="6" md="6" sm="6" className="column">
                      <input
                        type="text"
                        name="lastName"
                        placeholder="Last name"
                        className={`message-input ${
                          errors.lastName ? "error" : ""
                        }`}
                        value={formData.lastName}
                        onChange={handleInputChange}
                      />
                      {/* {errors.lastName && (
                        <span className="error-text">{errors.lastName}</span>
                      )} */}
                    </Col>
                  </Row>
                  <Row className="input-container">
                    <Col lg="6" xl="6" xxl="6" md="6" sm="6" className="column">
                      <input
                        type="text"
                        name="phone"
                        placeholder="Phone"
                        className={`message-input ${
                          errors.phone ? "error" : ""
                        }`}
                        value={formData.phone}
                        onChange={handleInputChange}
                        maxLength={10}
                        onKeyPress={handlePhoneKeyPress}
                      />
                      {/* {errors.phone && (
                        <span className="error-text">{errors.phone}</span>
                      )} */}
                    </Col>
                    <Col lg="6" xl="6" xxl="6" md="6" sm="6" className="column">
                      <input
                        type="text"
                        name="email"
                        placeholder="Email"
                        className={`message-input ${
                          errors.email ? "error" : ""
                        }`}
                        value={formData.email}
                        onChange={handleInputChange}
                      />
                      {/* {errors.email && (
                        <span className="error-text">{errors.email}</span>
                      )} */}
                    </Col>
                  </Row>
                  <Row className="input-container">
                    <Col
                      lg="12"
                      xl="12"
                      xxl="12"
                      md="12"
                      sm="12"
                      className="column"
                    >
                      <textarea
                        name="message"
                        placeholder="Message"
                        className={`message-textarea ${
                          errors.message ? "error" : ""
                        }`}
                        value={formData.message}
                        onChange={handleInputChange}
                      />
                      {/* {errors.message && (
                        <span className="error-text">{errors.message}</span>
                      )} */}
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12" className="column">
                      <button className="demo-btn" onClick={handleFormSubmit}>
                        BOOK A DEMO
                      </button>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Index;
