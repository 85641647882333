import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Slider } from "antd";
import debounce from "lodash/debounce";
import PizzaLogoWith from "../../images/pizzaLogo.svg";
import Tick from "../../images/tick.png";
import lineTwo from "../../images/line-2.png";
import Facebook from "../../images/facebook.png";
import Instagram from "../../images/instagram.png";
import Linkedin from "../../images/linkedin.png";
import TickWhite from "../../images/Tick-white.png";

import useWindowResize from "../../hooks/common/useWindowResize";

const Index = ({ handleFocus }) => {
  const getPopupContainer = () => document.body;
  const { screenWidth, screenHeight } = useWindowResize();

  const [ordersNumber, setOrdersNumber] = useState(0);
  const [costPerOrder, setCostPerOrder] = useState(1.95);
  const [totalPerMonth, setTotalPerMonth] = useState();
  const [youSave, setYouSave] = useState();

  const onChange = (newValue) => {
    // if (newValue < 25) {
    //   newValue = 25; // Set the minimum value to 25
    // }
    setOrdersNumber(newValue);
  };

  const calculateCosts = debounce((newValue) => {
    let costPerOrderUnitPrice = 0;
    if (newValue >= 1 && newValue <= 50) {
      costPerOrderUnitPrice = 1.99;
    } else if (newValue >= 51 && newValue <= 100) {
      costPerOrderUnitPrice = 1.99;
    } else if (newValue >= 101 && newValue <= 150) {
      costPerOrderUnitPrice = 1.99;
    } else if (newValue >= 151 && newValue <= 200) {
      costPerOrderUnitPrice = 1.99;
    } else if (newValue >= 201 && newValue <= 600) {
      costPerOrderUnitPrice = 1.99;
    }

    const costPerOrderUnit = costPerOrderUnitPrice.toFixed(2);
    const totalperMonthCal = parseInt((newValue * costPerOrderUnit).toFixed(2));
    const saved = parseInt(((30 / 100) * (newValue * 40)).toFixed(2));

    setCostPerOrder(costPerOrderUnit);
    setTotalPerMonth(totalperMonthCal);
    setYouSave(saved);
  }, 300); // Adjust debounce delay as needed

  useEffect(() => {
    calculateCosts(ordersNumber);
  }, [ordersNumber, calculateCosts]);

  const handleFreeTrail = () => {
    handleFocus();
  };

  const handletoRestogpt = () => {
    window.open("https://restogpt.ai", "_blank");
  };

  return (
    <>
      {" "}
      <Container>
        <Row>
          <Col lg="12">
            <div className="modal-section-header">
              <h1>
                Make More [Dough]<span>with</span>
                <img src={PizzaLogoWith} alt="pizza-logo-with" />
              </h1>
              <div className="modal-section-subpart">
                <div className="modal-section-block">
                  <img src={TickWhite} alt="Tick" />
                  $1 Setup Fee
                </div>
                <div className="modal-section-block">
                  <img src={TickWhite} alt="Tick" />
                  No Monthly Fee
                </div>
                <div className="modal-section-block">
                  <img src={TickWhite} alt="Tick" />
                  No Hidden Fees
                </div>
                <div className="modal-section-block">
                  <img src={TickWhite} alt="Tick" />
                  No Contract
                </div>
              </div>
            </div>
          </Col>
        </Row>
        {screenWidth > 992 ? (
          <>
            <Row>
              <Col lg="12">
                <div className="price-slider">
                  <Slider
                    value={ordersNumber}
                    min={0}
                    max={600}
                    onChange={onChange}
                    tooltip={{
                      formatter: null,
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <div className="cost-container">
                  <div className="cost-content">
                    <div className="cost-item">
                      <p className="cost-name">Orders</p>
                      <p className="cost-number">{ordersNumber}</p>
                    </div>
                    <div className="cost-item">
                      <p className="cost-name">Cost per order</p>
                      {/* <p className="cost-number">${costPerOrder}</p> */}
                      <p className="cost-number">$1.99</p>
                      <p className="cost-sample">can be passed to customer</p>
                    </div>
                    <div className="cost-item">
                      <p className="cost-name">Capped at</p>
                      <p className="cost-number">
                        $199 <span>/month</span>
                      </p>
                      <p className="cost-sample">unlimited orders</p>
                    </div>
                    <div className="cost-item">
                      <p className="cost-name">You save</p>
                      <p className="cost-number">${youSave}</p>
                      <p className="cost-sample">no 3rd party commission</p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </>
        ) : (
          <>
            <div className="slider-container-mobile">
              <Row>
                <Col lg="12">
                  <div className="price-slider">
                    <Slider
                      value={ordersNumber}
                      min={0}
                      max={600}
                      onChange={onChange}
                      // tipFormatter={(value) => `${value} orders`}
                      tooltip={{
                        formatter: null,
                      }}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg="12">
                  <div className="cost-container">
                    <div className="cost-content">
                      <div className="cost-item">
                        <div className="cost-item-group">
                          <p className="cost-name">Orders/mo.</p>
                          <p className="cost-number">{ordersNumber}</p>
                        </div>
                      </div>
                      <div className="cost-item">
                        <div className="cost-item-group">
                          <p className="cost-name">Save/mo.</p>
                          <p className="cost-number">${youSave}</p>
                          <span className="cost-star">*</span>
                        </div>
                        {/* <p className="cost-sample nextline">
                        no 3rd party commission
                        </p> */}
                      </div>
                      <div className="cost-item">
                        <div className="cost-item-group">
                          <p className="cost-name">Fee/order</p>
                          <p className="cost-number">$1.99</p>
                          <span className="cost-star">**</span>
                        </div>
                        {/* <p className="cost-sample">can be passed to customer</p> */}
                      </div>
                      <div className="cost-item">
                        <div className="cost-item-group">
                          <p className="cost-name">Capped at</p>
                          <p className="cost-number">
                            $199<span>/mo</span>
                          </p>
                          <span className="cost-star">***</span>
                        </div>
                        {/* <p className="cost-sample">unlimited orders</p> */}
                      </div>
                    </div>
                    <div className="star-container">
                      <div className="star-content">
                        <div>
                          <p>* no 3rd party commission</p>
                        </div>
                        <div>
                          <p>** can be paid by customer</p>
                        </div>
                        <div>
                          <p>*** unlimited orders</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </>
        )}
        <Row style={{ position: "relative" }}>
          <Col lg="12">
            <div className="trail-section">
              <div className="trail-container">
                <div className="trail-content">
                  <div className="price-btn-container">
                    <button className="price-btn" onClick={handleFreeTrail}>
                      Partner with PizzaBox AI
                    </button>
                  </div>
                  {/* {screenWidth > 992 ? (
                    <p className="price-para">
                      The only pizza platform that pays you back per order{" "}
                      <span>
                        – because <br />
                        it’s <span>made for you!</span>
                      </span>
                    </p>
                  ) : (
                    <p className="price-para">
                      The only pizza platform that pays you back per order{" "}
                      <span>
                        – because <br />
                        it’s <span>made for you!</span>
                      </span>
                    </p>
                  )} */}
                </div>
              </div>
            </div>
            <div
              className="modal-section-bottom-text"
              onClick={handletoRestogpt}
            >
              <p>
                Powered by RestoGPT AI <br />
                <span className="line">Built For Local Restaurants</span>
              </p>
            </div>
            <div className="modal-section-group-icon">
              <p>
                <a href="https://www.facebook.com/pizzaboxai">
                  <img
                    src={Facebook}
                    alt="facebook-icon"
                    className="footer-img"
                  />
                </a>
              </p>
              <p className="bottom-footer-text">
                <a href="https://www.instagram.com/pizzaboxai/">
                  <img
                    src={Instagram}
                    alt="instagram-icon"
                    className="footer-img"
                  />
                </a>
              </p>
              <p className="bottom-footer-text">
                <a href="https://www.linkedin.com/company/restogpt/">
                  <img
                    src={Linkedin}
                    alt="linkedin-icon"
                    className="footer-img"
                  />
                </a>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Index;
